import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FAQ } from './faq.model';
import { RouteService } from '../../route.service';

import { ToastService } from '../../toast.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class FAQService {
  faqs: FAQ[] = [];
  selectedFAQ: FAQ;

  baseUrl: string = environment.baseUrl;
  // At the start of the service the list of all possible branches and cashiers is
  // loaded for the filter dropdown selection
  constructor(
    private httpClient: HttpClient,
    private routeService: RouteService,
    public toastService: ToastService
  ) {}

  // get data returns 50 transactions from the offset
  // the method also checks whether there are further transactions or not
  // By loading 51 if the loaded transaction size is 51 then further transactions exists
  getData() {
    return new Promise((resolve, reject) => {
      const faqsArray: FAQ[] = [];
      const url: string = this.baseUrl + '/FAQ/Admin/All';
      this.httpClient.get<any>(url).subscribe(
        (faqs: any) => {
          for (const faq of faqs) {
            const tempFaq: FAQ = new FAQ(
              faq.faqID,
              faq.questionText,
              faq.questionAnswer,
              faq.questionText_ar,
              faq.questionAnswer_ar
            );
            faqsArray.push(tempFaq);
          }
          this.faqs = faqsArray;
          resolve(this.faqs);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  addFAQ(
    questionText: string,
    questionAnswer: string,
    questionText_ar: string,
    questionAnswer_ar: string
  ) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/FAQ/Admin/Add';
      this.httpClient
        .post(url, {
          questionText: questionText,
          questionAnswer: questionAnswer,
          questionText_ar: questionText_ar,
          questionAnswer_ar: questionAnswer_ar,
        })
        .subscribe(
          () => {
            resolve('success');
            this.toastService.show('success', 'Success', 'FAQ was added successfully');
          },
          (error) => {
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }

  updateFAQ(
    questionText: string,
    questionAnswer: string,
    questionText_ar: string,
    questionAnswer_ar: string
  ) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/FAQ/Admin/Edit';
      this.httpClient
        .post(url, {
          questionText: questionText,
          questionAnswer: questionAnswer,
          questionText_ar: questionText_ar,
          questionAnswer_ar: questionAnswer_ar,
          faqID: this.selectedFAQ.faqID,
        })
        .subscribe(
          () => {
            resolve('success');
            this.toastService.show('success', 'Success', 'FAQ was updated successfully');
          },
          (error) => {
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }

  deleteFAQ() {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/FAQ/Admin/Delete';
      this.httpClient
        .post(url, {
          faqID: this.selectedFAQ.faqID,
        })
        .subscribe(
          () => {
            resolve('success');
            this.toastService.show('success', 'Success', 'FAQ was deleted successfully');
          },
          (error) => {
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }
}
