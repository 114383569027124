import * as moment from 'moment';

export class PickupRequest {
  pickupOnlineRequestID: number;
  pickUpRequestID?: number;
  requestDate: moment.Moment;
  requestedOn: moment.Moment;
  childID: number;
  externalStudentID: string;
  fullName: string;
  grade: string;
  className: string;
  adminID?: number;
  adminName?: number;
  adminUsername?: number;

  constructor(raw: any) {
    Object.assign(this, raw);

    this.requestedOn = moment(raw.requestedOn);
    this.requestDate = moment(raw.requestDate);
  }
}
