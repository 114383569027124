import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Admin, OrganizationAdmin } from './admins/admin.model';
import { AdminRole } from './admins/adminRole.model';
import { Merchant } from '../pages/dashboard/merchant.model';
import { RouteService } from '../route.service';
import { PeripheralAccess } from '../pages/csvUploader/peripheralAccess.model';

import { ToastService } from '../toast.service';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { lastValueFrom, throwError } from 'rxjs';
import { organizationPermissions, merchantPermissions } from '../utils/permissions';

@Injectable()
export class AuthService {
  possibleMerchant: string[] = [];
  merchantIdMap = new Map();
  merchantNameMap = new Map();
  selectedAdmin: Admin;
  fromLogin: boolean;
  permissionCategory: string;
  selectedPermission: any;

  baseUrl: string = environment.baseUrl;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private routeService: RouteService,
    private toastService: ToastService
  ) {}

  // register the user by contacting the backend api providing it with the suitable params
  register(
    username: string,
    password: string,
    name: string,
    merchantID: number,
    email: string,
    phoneNumber: string
  ) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/api/auth/registerAdmin';
      const req = this.httpClient.post(url, {
        username: username,
        password: password,
        name: name,
        merchantID: merchantID,
        email: email,
        phoneNumber: phoneNumber,
      });
      req.subscribe(
        () => {
          resolve('success');
          this.toastService.show('success', 'Success', 'Admin Registered successfully');
        },
        (error: any) => {
          this.routeService.checkErr(error).then((err: any) => {
            console.log('error result', err);
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  getMerchant() {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Merchants/Admin/Current';
      this.httpClient.get<any>(url).subscribe(
        (result: any) => {
          const currentMerchant: Merchant = new Merchant(
            result[0].merchantID,
            result[0].name,
            result[0].colorLogoURL,
            result[0].monochromeLogoURL,
            result[0].imageURL,
            result[0].color,
            result[0].darkUI !== 0,
            result[0].primaryCategoryID,
            result[0].secondaryCategoryID,
            result[0].phoneNumber,
            result[0].description
          );
          resolve(currentMerchant);
        },
        (error: any) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error Retrieving Merchant Data', err.err);
          });
        }
      );
    });
  }

  // change the password of an admin by sending the old and new passwords
  // the authorization token is also needed in the header
  changePassword(oldPassword: string, newPassword: string) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/api/auth/UpdateAdminPassword';
      this.httpClient
        .post(url, {
          password: oldPassword,
          newPassword: newPassword,
        })
        .subscribe(
          () => {
            resolve('success');
            this.toastService.show('success', 'Success', 'Password Changed Successfully');
          },
          (error: any) => {
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }

  // Get the list of all the possible merchants for the drop down selector
  getMerchants() {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Merchants/Admin/All';
      this.possibleMerchant = [];
      const req = this.httpClient.get(url);
      req.subscribe(
        (res: any) => {
          for (const merchant of res) {
            this.possibleMerchant.push(merchant.name);
            this.merchantIdMap.set(merchant.name, merchant.merchantID);
            this.merchantNameMap.set(merchant.merchantID, merchant.name);
          }
          resolve(this.possibleMerchant);
        },
        (error: any) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error Loading Merchants', err.err);
          });
        }
      );
    });
  }

  getMerchantsWithID() {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Merchants/Admin/All';
      const merchants = [];
      const req = this.httpClient.get(url);
      req.subscribe(
        (res: any) => {
          for (const merchant of res) {
            merchants.push({
              merchantName: merchant.name,
              merchantID: merchant.merchantID,
            });
          }
          resolve(merchants);
        },
        (error: any) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error Loading Merchants', err.err);
          });
        }
      );
    });
  }

  // Set the merchant id of the user to the chosen merchant from the drop down menu
  setMerchant(merchant: string) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/api/auth/changeAdminMerchant';
      const merchantID = this.merchantIdMap.get(merchant);
      const req = this.httpClient.post(url, {
        newMerchantID: merchantID,
      });
      req.subscribe(
        () => {
          localStorage.setItem('spareMerchantID', '' + merchantID);
          resolve('success');
        },
        (error: any) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  // calls on the login api with the credentials and
  // saves the access token along with the admin role and the username of the admin
  login(username: string, password: string) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/api/auth/loginAdmin';
      const req = this.httpClient.post(url, {
        username: username,
        password: password,
      });
      req.subscribe(
        (response: any) => {
          localStorage.setItem('spareAdminUsername', response.admin.username);
          localStorage.setItem('spareToken', response.token);
          localStorage.setItem('spareAdminRole', response.admin.role);
          localStorage.setItem('spareView', 'merchant');
          this.getMerchant().then((merchant: Merchant) => {
            if (merchant === null) {
              localStorage.setItem('spareMerchantID', null);
              localStorage.setItem('spareView', 'organization');
            } else {
              localStorage.setItem('spareMerchantID', '' + merchant.merchantID);
            }
            this.getAdminRolesByID().then((res: AdminRole[]) => {
              let organizationsRoles = '';
              for (const role of res) {
                organizationsRoles =
                  organizationsRoles +
                  ',' +
                  role.organizationID +
                  ':' +
                  role.organizationName +
                  ':' +
                  role.role +
                  ':' +
                  (role.handlesBilling ? 't' : 'f');
              }
              localStorage.setItem('spareRoles', organizationsRoles);
              if (localStorage.getItem('spareView') === 'organization') {
                localStorage.setItem(
                  'spareSelectedOrganization',
                  organizationsRoles.split(',')[1].split(':')[0]
                );
                localStorage.setItem(
                  'spareSelectedOrganizationName',
                  organizationsRoles.split(',')[1].split(':')[1]
                );
                localStorage.setItem(
                  'spareSelectedOrganizationRole',
                  organizationsRoles.split(',')[1].split(':')[2]
                );
                localStorage.setItem(
                  'spareShowBilling',
                  organizationsRoles.split(',')[1].split(':')[3]
                );
              }
              resolve('success');
            });
          });
        },
        (error: any) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
          this.removeTokens();
        }
      );
    });
  }

  // Reset Password
  resetPassword(username: string) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/api/auth/checkAdminUsernameExists';
      const req = this.httpClient.post(url, {
        username: username,
      });
      req.subscribe(
        (response: any) => {
          localStorage.setItem('spareEmailToken', response.token);
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const url: string = this.baseUrl + '/Verify/Admin/PasswordResetCode';
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const req = this.httpClient.get(url);
          req.subscribe(
            () => {
              resolve('success');
            },
            (error: any) => {
              this.routeService.checkErr(error).then((err: any) => {
                reject(err);
                this.toastService.show('danger', 'Error', err.err);
              });
            }
          );
        },
        (error: any) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  // Verify reset password code
  verifyResetCode(code: string) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Verify/Admin/CheckPasswordResetCode';
      const req = this.httpClient.post(url, {
        code: code,
      });
      req.subscribe(
        (response: any) => {
          localStorage.setItem('spareEmailToken', response.JWT);
          resolve('success');
        },
        (error: any) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  setResetPassword(password: string) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/api/auth/Reset/UpdateAdminPassword';
      const req = this.httpClient.post(url, {
        newPassword: password,
      });
      req.subscribe(
        (response: any) => {
          localStorage.setItem('spareAdminUsername', response.admin.username);
          localStorage.setItem('spareToken', response.token);
          localStorage.setItem('spareAdminRole', response.admin.role);
          localStorage.setItem('spareView', 'merchant');
          this.getMerchant().then((merchant: Merchant) => {
            if (merchant === null) {
              localStorage.setItem('spareMerchantID', null);
              localStorage.setItem('spareView', 'organization');
            } else {
              localStorage.setItem('spareMerchantID', '' + merchant.merchantID);
            }
            this.getAdminRolesByID().then((res: AdminRole[]) => {
              let organizationsRoles = '';
              for (const role of res) {
                organizationsRoles =
                  organizationsRoles +
                  ',' +
                  role.organizationID +
                  ':' +
                  role.organizationName +
                  ':' +
                  role.role +
                  ':' +
                  (role.handlesBilling ? 't' : 'f');
              }
              localStorage.setItem('spareRoles', organizationsRoles);
              if (localStorage.getItem('spareView') === 'organization') {
                localStorage.setItem(
                  'spareSelectedOrganization',
                  organizationsRoles.split(',')[1].split(':')[0]
                );
                localStorage.setItem(
                  'spareSelectedOrganizationName',
                  organizationsRoles.split(',')[1].split(':')[1]
                );
                localStorage.setItem(
                  'spareSelectedOrganizationRole',
                  organizationsRoles.split(',')[1].split(':')[2]
                );
                localStorage.setItem(
                  'spareShowBilling',
                  organizationsRoles.split(',')[1].split(':')[3]
                );
              }
              resolve('success');
              this.toastService.show('success', 'Success', 'Password reset successfully');
            });
          });
        },
        (error: any) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  // calls on the api that checks the validity of the token
  // If the token could not be validated the user is redirected to the login page and all access tokens are removed
  async validateToken() {
    if (!localStorage.getItem('spareToken')) {
      this.router.navigate(['./auth/login']);
      return;
    }

    try {
      const res: any = await this.getProtectedRoute();

      if (res.role !== +localStorage.getItem('spareAdminRole')) {
        localStorage.setItem('spareAdminRole', res.role);
        alert(
          'Your permissions have been modified by your system administrator. The page will refresh to reflect these changes.'
        );
        return location.reload();
      }
      const adminRole: AdminRole[] = await this.getAdminRolesByID();
      const organizationsRoles = await this.buildOrganizationRolesString(adminRole);

      if (organizationsRoles !== localStorage.getItem('spareRoles')) {
        console.log('org roles changed', organizationsRoles, localStorage.getItem('spareRoles'));
        localStorage.setItem('spareRoles', organizationsRoles);
        if (localStorage.getItem('spareView') === 'organization') {
          const selectedOrganization = localStorage.getItem('spareSelectedOrganization');
          const newSelectedOrganizationRole: any = await this.findSelectedOrganizationAdminRole(
            adminRole,
            selectedOrganization
          );
          localStorage.setItem(
            'spareShowBilling',
            newSelectedOrganizationRole.handlesBilling ? 't' : 'f'
          );
          localStorage.setItem('spareSelectedOrganizationRole', newSelectedOrganizationRole.role);
        }
        alert(
          'Your permissions have been modified by your system administrator. The page will refresh to reflect these changes.'
        );
        return location.reload();
      }
    } catch (err) {
      if (err.status === 401) {
        this.router.navigate(['./auth/login']);
        this.removeTokens();
      } else {
        this.routeService.checkErr(err).then((err: any) => {
          this.toastService.show('danger', 'Error', err.err);
        });
      }
    }
  }

  async findSelectedOrganizationAdminRole(orgRoles, selectedOrganization) {
    let selectedRole;
    for (const role of orgRoles) {
      if (role.organizationID === +selectedOrganization) {
        selectedRole = role;
      }
    }
    return selectedRole;
  }

  async buildOrganizationRolesString(orgRoles) {
    let organizationsRoles = '';
    for (const role of orgRoles) {
      organizationsRoles =
        organizationsRoles +
        ',' +
        role.organizationID +
        ':' +
        role.organizationName +
        ':' +
        role.role +
        ':' +
        (role.handlesBilling ? 't' : 'f');
    }
    return organizationsRoles;
  }

  getProtectedRoute() {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/api/auth/protectedAdmin';
      this.httpClient.get(url).subscribe(
        (res: any) => {
          return resolve(res);
        },
        (err) => {
          return reject(err);
        }
      );
    });
  }

  getAdminsData() {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/AdminUsers/Admin/All';
      const admins: Admin[] = [];

      this.httpClient.get(url).subscribe(
        (res: any) => {
          for (const admin of res) {
            const tempAdmin: Admin = new Admin(
              admin.adminID,
              admin.name,
              admin.username,
              admin.role,
              admin.merchantID,
              admin.email,
              admin.phoneNumber,
              admin.merchantName
            );
            admins.push(tempAdmin);
          }
          resolve(admins);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  updateSelectedAdmin(admin: Admin) {
    this.selectedAdmin = admin;
  }

  getAdminRoles() {
    return new Promise<AdminRole[]>((resolve, reject) => {
      const url: string = this.baseUrl + '/Organizations/Admin/AdminID';
      const req = this.httpClient.get(url, {
        params: new HttpParams().set('adminID', '' + this.selectedAdmin.adminID),
      });
      req.subscribe(
        (res: AdminRole[]) => {
          resolve(res);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  getAdminRolesByID() {
    return new Promise<AdminRole[]>((resolve, reject) => {
      const url: string = this.baseUrl + '/AdminUsers/Admin/OrganizationsByAdmin';
      const req = this.httpClient.get(url);
      req.subscribe(
        (res: AdminRole[]) => {
          resolve(res);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  getOrganizationAdminsByRole(roles: number) {
    const url = this.baseUrl + '/AdminUsers/Admin/GetOrgAdminsWithPermissions';
    const organizationID = +localStorage.getItem('spareSelectedOrganization');

    const source$ = this.httpClient.get<Promise<OrganizationAdmin[]>>(url, {
      params: { roles, organizationID },
    });

    return lastValueFrom(source$);
  }

  // Removes the stored access tokens from the browser
  removeTokens() {
    localStorage.removeItem('spareToken');
    localStorage.removeItem('spareAdminUsername');
    localStorage.removeItem('spareAdminRole');
    localStorage.removeItem('spareMerchantID');
    localStorage.removeItem('spareRoles');
    localStorage.removeItem('spareView');
    localStorage.removeItem('spareSelectedOrganization');
    localStorage.removeItem('spareSelectedOrganizationRole');
    localStorage.removeItem('spareSelectedOrganizationName');
    localStorage.removeItem('spareShowBilling');
  }

  // returns whether a user with the current admin role has permission to do the desired action

  hasPermissionMerchant(action: number) {
    return (action & +localStorage.getItem('spareAdminRole')) !== 0;
  }

  hasPermissionOrganization(action: number) {
    return (action & +localStorage.getItem('spareSelectedOrganizationRole')) !== 0;
  }
  
  /**
     * Making update admin roles in the server
     * @param body {{
        selectedPermissionsIDs: number[],
        organizationsRoles: AdminRole[]}
     @returns {Promise<any>}
     */
  updateMerchantAdminRoles(body: {
    selectedPermissionsIDs: number[];
    organizationsRoles: AdminRole[];
  }): Promise<any> {
    // TODO: separate this calls.
    const url: string = this.baseUrl + '/AdminUsers/Admin/ChangeAdminRole';
    return this.httpClient
      .post(url, {
        adminID: this.selectedAdmin.adminID,
        role: body.selectedPermissionsIDs,
      })
      .pipe(catchError(this._errorHandler(this.routeService)))
      .toPromise();
  }

  addAdminToOrganization(admin: number, organization: number) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/AdminUsers/Admin/AddToOrganization';
      const req = this.httpClient.post(url, {
        adminID: this.selectedAdmin.adminID,
        organizationID: organization,
      });
      req.subscribe(
        () => {
          resolve('success');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  removeAdminFromOrganization(organizationAdminID: number) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/AdminUsers/Admin/RemoveFromOrganization';
      const req = this.httpClient.post(url, {
        organizationAdminID: organizationAdminID,
      });
      req.subscribe(
        () => {
          resolve('success');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  changeOrganizationAdminRole(organizationAdminID: number, permissions: number[]) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/AdminUsers/Admin/ChangeAdminOrganizationRole';
      const req = this.httpClient.post(url, {
        organizationAdminID: organizationAdminID,
        role: permissions,
      });
      req.subscribe(
        () => {
          resolve('success');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  uploadPeripherals(peripherals: any) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Peripherals/RegisterBulk';
      const req = this.httpClient.post(url, {
        peripherals: peripherals,
      });
      req.subscribe(
        (response: any) => {
          resolve(response);
          this.toastService.show('success', 'Success', 'Perephirals File uploaded successfully');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  uploadChildren(children: any) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Child/Admin/Upload';
      const req = this.httpClient.post(url, { children: children });
      req.subscribe(
        (response: any) => {
          resolve(response);
          this.toastService.show('success', 'Success', 'Children File uploaded successfully');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  sendNotification(title: string, body: string, type: string, topic: string, userID: number) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Notifications/Admin/Send';
      let postBody;
      if (topic && topic !== '') {
        postBody = {
          title: title,
          body: body,
          type: type,
          topic: topic,
        };
      } else {
        postBody = {
          title: title,
          body: body,
          type: type,
          userID: userID,
        };
      }
      const req = this.httpClient.post(url, postBody);
      req.subscribe(
        () => {
          resolve('success');
          this.toastService.show('success', 'Success', 'Notification sent successfully');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  getPeripheralsForAccess() {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Peripherals/Admin/AllSuper';
      const req = this.httpClient.get(url);
      req.subscribe(
        (res: PeripheralAccess[]) => {
          resolve(res);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  getPermissions() {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/AdminUsers/Admin/GetPermissions';
      const req = this.httpClient.get(url);
      req.subscribe(
        (res: any) => {
          const permissions: any[] = [];
          for (const permission of res) {
            if (permission.grantedBy & +localStorage.getItem('spareAdminRole')) {
              permissions.push(permission);
            }
          }
          resolve(permissions);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  getOrgPermissions() {
    return new Promise<any[]>((resolve, reject) => {
      const url: string = this.baseUrl + '/AdminUsers/Admin/GetOrgPermissions';
      this.httpClient.get(url).subscribe(
        (res: any[]) => {
          const permissions: any[] = [];
          for (const permission of res) {
            if (permission.grantedBy & +localStorage.getItem('spareAdminRole')) {
              permissions.push(permission);
            }
          }
          resolve(permissions);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  /**
   * Super Admin - hide all others
   * Store Admin - hide all others except for alter branches
   * Spare Support - hide super admin and store admin (Automatically select change Merchant)
   * Organization Roll out - hide super admin and store admin
   * (Automatically select change Merchant,
   * Alter Admins super,
   * Alter Branches,
   * Alter Merchants,
   * Alter Cashiers,
   * Alter Machines Super,
   * Register Children,
   * Alter Organizations)
   * Finance - hide all except record bills, view bills (Automatically select view bills)
   * phone auth - hide all except manual overrides and select it
   * Attendance - hide all except alter attendance and view attendance . automatically select view attendance
   * custom Admin - hide super and store Admin leave the rest unselected
   * shift management -
   * stock management -
   */

  processPredefinedRole(role: number) {
    let predefinedRole = 'custom';
    if (role & merchantPermissions.SUPER_ADMIN) {
      return 'superAdmin';
    }
    if (role & merchantPermissions.STORE_ADMIN) {
      return 'storeAdmin';
    }
    if (role & merchantPermissions.SPARE_SUPPORT) {
      predefinedRole = 'spareSupport';
    }
    if (role & (merchantPermissions.RECORD_BILLS_PAID + merchantPermissions.VIEW_BILLS)) {
      predefinedRole = 'finance';
    }
    if (role & merchantPermissions.MANUAL_OVERRIDES) {
      if (predefinedRole !== 'custom') {
        return 'custom';
      }
      predefinedRole = 'phoneAuth';
    }
    if (
      role & merchantPermissions.CHANGE_MERCHANT &&
      role & merchantPermissions.ALTER_ADMINS &&
      role & merchantPermissions.ALTER_BRANCHES &&
      role & merchantPermissions.ALTER_MERCHANTS &&
      role & merchantPermissions.ALTER_CASHIERS &&
      role & merchantPermissions.ALTER_MACHINES_SUPER &&
      role & merchantPermissions.REGISTER_CHILDREN &&
      role & merchantPermissions.ALTER_ORGANIZATION
    ) {
      if (predefinedRole !== 'custom') {
        return 'custom';
      }
      predefinedRole = 'organizationRollOut';
    }
    if (
      role & merchantPermissions.VIEW_SHIFTS &&
      role & (merchantPermissions.VIEW_CASHIERS + merchantPermissions.ALTER_CASHIERS)
    ) {
      if (predefinedRole !== 'custom') {
        return 'custom';
      }
      predefinedRole = 'shiftManagment';
    }
    if (role & (merchantPermissions.VIEW_INVENTORY + merchantPermissions.ALTER_INVENTORY)) {
      if (predefinedRole !== 'custom') {
        return 'custom';
      }
      predefinedRole = 'stockManagment';
    }
    return predefinedRole;
  }

  processOrgPredefinedRole(role: number) {
    let predefinedRole = 'custom';
    if (role & merchantPermissions.SUPER_ADMIN) {
      return 'superAdmin';
    }
    if (role & organizationPermissions.ORGANIZATION_ADMIN) {
      return 'organizationAdmin';
    }
    if (
      role &
      (organizationPermissions.ORGANIZATION_RECORD_BILLS +
        organizationPermissions.ORGANIZATION_VIEW_BILLS)
    ) {
      predefinedRole = 'finance';
    }
    if (
      role &
      (organizationPermissions.ORGANIZATION_VIEW_ATTENDANCE +
        organizationPermissions.ORGANIZATION_ALTER_ATTENDANCE)
    ) {
      if (predefinedRole !== 'custom') {
        return 'custom';
      }
      predefinedRole = 'attendance';
    }
    return predefinedRole;
  }

  predefinedRoleChanged(predefinedRole: string) {
    switch (predefinedRole) {
      case 'superAdmin':
        return merchantPermissions.SUPER_ADMIN;
      case 'storeAdmin':
        return merchantPermissions.STORE_ADMIN;
      case 'spareSupport':
        return merchantPermissions.SPARE_SUPPORT;
      case 'organizationRollOut':
        return (
          merchantPermissions.CHANGE_MERCHANT +
          merchantPermissions.ALTER_ADMINS +
          merchantPermissions.ALTER_BRANCHES +
          merchantPermissions.ALTER_MERCHANTS +
          merchantPermissions.ALTER_CASHIERS +
          merchantPermissions.ALTER_MACHINES_SUPER +
          merchantPermissions.REGISTER_CHILDREN +
          merchantPermissions.ALTER_ORGANIZATION
        );
      case 'finance':
        return merchantPermissions.VIEW_BILLS;
      case 'phoneAuth':
        return merchantPermissions.MANUAL_OVERRIDES;
      case 'shiftManagment':
        return merchantPermissions.VIEW_SHIFTS + merchantPermissions.VIEW_CASHIERS;
      case 'stockManagment':
        return merchantPermissions.VIEW_INVENTORY;
      default:
        return 0;
    }
  }

  orgPredefinedRoleChanged(orgPredefinedRole: string) {
    switch (orgPredefinedRole) {
      case 'superAdmin':
        return merchantPermissions.SUPER_ADMIN;
      case 'organizationAdmin':
        return organizationPermissions.ORGANIZATION_ADMIN;
      case 'finance':
        return organizationPermissions.ORGANIZATION_VIEW_BILLS;
      case 'attendance':
        return organizationPermissions.ORGANIZATION_VIEW_ATTENDANCE;
      default:
        return 0;
    }
  }

  addPermission(desc: string, grantedBy: number) {
    return new Promise((resolve, reject) => {
      let url: string;
      if (this.permissionCategory === 'Merchant') {
        url = this.baseUrl + '/AdminUsers/Admin/AddPermissionMerchant';
      } else {
        url = this.baseUrl + '/AdminUsers/Admin/AddPermissionOrganization';
      }
      const req = this.httpClient.post(url, {
        desc: desc,
        grantedBy: grantedBy,
      });
      req.subscribe(
        (response: any) => {
          resolve(response);
          this.toastService.show('success', 'Success', 'Permission Added Successfully');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  updatePermission(grantedBy: number) {
    return new Promise((resolve, reject) => {
      let url: string;
      if (this.permissionCategory === 'Merchant') {
        url = this.baseUrl + '/AdminUsers/Admin/UpdatePermissionMerchant';
      } else {
        url = this.baseUrl + '/AdminUsers/Admin/UpdatePermissionOrganization';
      }
      const req = this.httpClient.post(url, {
        permissionID: this.selectedPermission.id,
        grantedBy: grantedBy,
      });
      req.subscribe(
        (response: any) => {
          this.selectedPermission = null;
          resolve(response);
          this.toastService.show('success', 'Success', 'Permission Updated Successfully');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  /**
   * Wrapper on routerCheck error to use in pipe operation
   * @param routerService: router service to check the error
   * @private
   * @return {(error: any) => ErrorObservable}
   */
  // private _errorHandler(routerService: RouteService): (error: any) => ErrorObservable<any> {
  //     return (error): ErrorObservable<any> => {
  //         routerService.checkErr(error).then((err: any) => {
  //             this.toastService.showToast('danger', 'Error', err.err);
  //         });
  //         return Observable.throw(error);
  //     }
  // }

  private _errorHandler(routeService: RouteService) {
    return (error: any) => {
      routeService.checkErr(error).then((err: any) => {
        this.toastService.show('danger', 'Error', err.err);
      });
      return throwError(error);
    };
  }
}
