import * as moment from 'moment';

export class BusNotification {
  notificationID: number;
  tripID: number;
  routeID: number;
  type: string;
  title: string;
  message: string;
  timestamp: moment.Moment;

  constructor(raw: any) {
    Object.assign(this, raw);
    this.timestamp = moment(this.timestamp);
  }
}
