import { SuperBillItem } from './superBillItem.model';

export class SuperBill {
  billItems: SuperBillItem[];
  spareToPay: number;
  merchantToPay: number;
  invoiceNumber: string;
  sparePaidOn: string;
  merchantPaidOn: string;
  merchantName: string;
  startDate: string;
  endDate: string;
  spareDue: string;
  merchantDue: string;
  totalReversals: number;
  organizationName: string;
  statusMerchant: string;
  statusSpare: string;
  beingDownloadedSpare: boolean;
  beingDownloadedMerchant: boolean;

  constructor(billItems: SuperBillItem[]) {
    this.billItems = billItems;
    this.sparePaidOn = 'Unpaid';
    this.merchantPaidOn = 'Unpaid';
    this.spareToPay = 0;
    this.merchantToPay = 0;
    this.totalReversals = 0;
    let saprePaidNumber = 0;
    let merchantPaidNumber = 0;
    for (const billingItem of this.billItems) {
      this.spareToPay =
        this.spareToPay + billingItem.totalSalesValue - billingItem.totalReversalValue;
      this.merchantToPay = this.merchantToPay + billingItem.EGPIn;
      this.totalReversals = this.totalReversals + billingItem.totalReversalValue;
      if (billingItem.merchantPaidOn !== null) {
        merchantPaidNumber = merchantPaidNumber + 1;
      }
      if (billingItem.sparePaidOn !== null || billingItem.branchName === 'Credit Card') {
        saprePaidNumber = saprePaidNumber + 1;
      }
    }
    if (merchantPaidNumber === 0) {
      this.statusMerchant = 'UNSETTLED';
    } else {
      if (merchantPaidNumber === billItems.length) {
        this.statusMerchant = 'SETTLED';
      } else {
        this.statusMerchant = 'PARTIALLY SETTLED';
      }
    }
    if (saprePaidNumber === 0) {
      this.statusSpare = 'UNSETTLED';
    } else {
      if (saprePaidNumber === billItems.length) {
        this.statusSpare = 'SETTLED';
      } else {
        this.statusSpare = 'PARTIALLY SETTLED';
      }
    }
    this.spareToPay = +this.spareToPay.toFixed(2);
    this.merchantToPay = +this.merchantToPay.toFixed(2);
    if (billItems.length > 0) {
      this.invoiceNumber = billItems[0].invoiceNumber + '';
      this.merchantName = billItems[0].merchantName;
      this.startDate = billItems[0].startDate;
      this.endDate = billItems[0].endDate;
      this.spareDue = billItems[0].spareToMerchantDue;
      this.merchantDue = billItems[0].merchantToSpareDue;
      this.organizationName = billItems[0].organizationName;
    }
    this.beingDownloadedSpare = false;
    this.beingDownloadedMerchant = false;
    while (this.invoiceNumber.length < 6) this.invoiceNumber = '0' + this.invoiceNumber;
  }
}
