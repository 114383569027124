import * as moment from 'moment';

export class Transaction {
  transactionID: number;
  branchID: number;
  timestamp: string;
  amount: number;
  area: string;
  branchName: string;
  type: string;
  cashierUsername: string;
  creditAccountID: number;
  debitAccountID: number;
  transactionReference: string;
  discountAmount: number;
  discountPercentage: number;
  couponCode: string;
  paymentMethod: string;
  organizationName: string;

  constructor(raw: any) {
    Object.assign(this, raw);

    if (raw['Payment Method']) {
      this.paymentMethod = raw['Payment Method'];
    }

    this.timestamp = moment(raw.timestamp).format('Do MMMM YYYY, h:mm:ss a');
  }
}
