import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastService } from '../../../toast.service';

@Injectable()
export class OverdraftGuard implements CanActivate {
  constructor(private router: Router, private toastService: ToastService) {}
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    const roleCode = +localStorage.getItem('spareAdminRole');
    if (roleCode & (1 + 2048)) {
      return true;
    } else {
      this.router.navigate(['../']).then(() => {
        this.toastService.show(
          'danger',
          'Auth Error',
          "You don't have the permission to view this content",
          5000
        );
      });
      return false;
    }
  }
}
