import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (window.localStorage.getItem('spareToken')) {
      return true;
    } else {
      console.log('navigated here');
      this.router.navigate(['../auth']);
      return false;
    }
  }
}
