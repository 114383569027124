import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RouteService } from '../../../route.service';
import { ToastService } from '../../../toast.service';

import { PeripheralItem } from './peripheral.model';
import { environment } from '../../../../environments/environment';
import { lastValueFrom } from 'rxjs';
@Injectable()
export class PeripheralsService {
  baseUrl: string = environment.baseUrl;

  constructor(
    private httpClient: HttpClient,
    private routeService: RouteService,
    private toastService: ToastService
  ) {}

  loadPeripheralsAtOrg(organizationID) {
    return new Promise((resolve, reject) => {
      const url = `${this.baseUrl}/organization/peripherals/?organizationID=${organizationID}`;
      const response = this.httpClient.get(url);

      response.subscribe(
        (peripherals: any) => {
          const peripheralItems = [];
          for (const peripheral of peripherals) {
            const tempItem: PeripheralItem = new PeripheralItem(peripheral);
            peripheralItems.push(tempItem);
          }
          resolve(peripheralItems);
        },
        async (err) => {
          const formattedError: any = await this.routeService.checkErr(err);
          this.toastService.show('danger', 'Error', formattedError.err);
          reject(formattedError);
        }
      );
    });
  }

  linkPeripheral(
    peripheralID: number,
    externalStudentID: string,
    organizationID: number
  ): Promise<any> {
    const url = `${this.baseUrl}/organization/peripherals/${peripheralID}/link`;
    const source$ = this.httpClient.post(url, {
      organizationID,
      externalStudentID,
    });

    return lastValueFrom(source$);
  }

  pairPeripheral(peripheralID: number, organizationID: number): Promise<any> {
    const url = `${this.baseUrl}/organization/peripherals/${peripheralID}/pair?organizationID=${organizationID}`;
    const source$ = this.httpClient.post(url, {});

    return lastValueFrom(source$);
  }

  unlinkPeripheral(peripheralID: number, organizationID: number): Promise<any> {
    const url = `${this.baseUrl}/organization/peripherals/${peripheralID}/unlink?organizationID=${organizationID}`;
    const source$ = this.httpClient.delete(url);

    return lastValueFrom(source$);
  }

  unpairPeripheral(peripheralID: number, organizationID: number): Promise<any> {
    const url = `${this.baseUrl}/organization/peripherals/${peripheralID}/unpair?organizationID=${organizationID}`;
    const source$ = this.httpClient.delete(url);

    return lastValueFrom(source$);
  }

  updatePeripheralStatus(
    peripheralID: number,
    organizationID: number,
    isActive: boolean
  ): Promise<any> {
    const url = `${this.baseUrl}/organization/peripherals/${peripheralID}/status?organizationID=${organizationID}`;
    const source$ = this.httpClient.put(url, { isActive });

    return lastValueFrom(source$);
  }

  updatePeripheralSerial(
    peripheralID: number,
    organizationID: number,
    newSerial: string
  ): Promise<any> {
    const url = `${this.baseUrl}/organization/peripherals/switch-serial`;
    const source$ = this.httpClient.put(url, { peripheralID, organizationID, newSerial });

    return lastValueFrom(source$);
  }
}
