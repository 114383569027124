export class Allergen {
  allergenID: number;
  allergenName: string;
  allergenName_ar: string;
  allergenIcon: string;

  constructor(
    allergenID: number,
    allergenName: string,
    allergenName_ar: string,
    allergenIcon: string
  ) {
    this.allergenID = allergenID;
    this.allergenName = allergenName;
    this.allergenName_ar = allergenName_ar;
    this.allergenIcon = this.checkURL(allergenIcon);
  }

  checkURL(url: string) {
    if (url === null || url.startsWith('https') || url.startsWith('http')) {
      return url;
    } else {
      return 'https://' + url;
    }
  }
}
