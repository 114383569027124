import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouteService } from '../../route.service';
import { ToastService } from '../../toast.service';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { lastValueFrom, map } from 'rxjs';
import { Guardian } from './models/guardian.model';

@Injectable()
export class ApprovedGuardiansService {
  constructor(
    private httpClient: HttpClient,
    private routeService: RouteService,
    private toastService: ToastService
  ) {}

  baseUrl: string = environment.baseUrl;
  orgId = localStorage.getItem('spareSelectedOrganization');

  getApprovedGuardians() {
    return new Promise((resolve, reject) => {
      const url = this.baseUrl + '/family-account/approved-guardian/?organizationID=' + this.orgId;
      this.httpClient.get(url).subscribe(
        (res: any) => {
          for (const item of res) {
            item.addedOn = this.formatDate(item.addedOn);
          }
          resolve(res);
        },
        (error) => {
          reject(error);
          this.toastService.show('danger', 'Error', error.err);
        }
      );
    });
  }

  addApprovedGuardian(
    guardianName: string,
    guardianArabicName: string,
    guardianNationalID: string,
    childID: number,
    peripheralSerial: string
  ) {
    return this.addApprovedGuardians([
      { guardianName, guardianArabicName, guardianNationalID, childID, peripheralSerial },
    ]);
  }

  addApprovedGuardians(guardians: any[]) {
    const url =
      this.baseUrl + `/family-account/approved-guardian/?organizationID=${encodeURI(this.orgId)}`;
    const source$ = this.httpClient.post(url, guardians);

    return lastValueFrom(source$);
  }

  deleteApprovedGuardian(id) {
    return new Promise((resolve, reject) => {
      const url = this.baseUrl + '/family-account/approved-guardian/' + id;
      this.httpClient.delete(url).subscribe(
        (res) => {
          resolve(res);
          this.toastService.show('success', 'Success', 'Guardian Deleted');
        },
        (error) => {
          reject(error);
          this.toastService.show('danger', 'Error', error.err);
        }
      );
    });
  }

  getGuardianActivity(dateRange?) {
    return new Promise((resolve, reject) => {
      let url =
        this.baseUrl + '/organization/approved-guardian-check/?organizationID=' + this.orgId;
      let dateFrom = null,
        dateTo = null;
      if (dateRange && dateRange.startDate && dateRange.endDate) {
        dateFrom = moment(dateRange.startDate).format('YYYY-MM-DD HH:mm:ss');
        dateTo = moment(dateRange.endDate).format('YYYY-MM-DD HH:mm:ss');
        if (dateFrom && dateTo) url += `&from=${encodeURI(dateFrom)}&to=${encodeURI(dateTo)}`;
      }
      this.httpClient.get(url).subscribe(
        (res: any) => {
          console.log(res);
          for (const item of res) {
            item.timeTapped = this.formatDate(item.timeTapped);
            item.timeApproved = item.timeApproved ? this.formatDate(item.timeApproved) : null;
          }
          resolve(res);
        },
        (error) => {
          reject(error);
          this.toastService.show('danger', 'Error', error.err);
        }
      );
    });
  }

  getGuardianByChildID(childID: number): Promise<Guardian[]> {
    const url = `${this.baseUrl}/family-account/approved-guardian/child/by-child-id/${childID}`;

    const source$ = this.httpClient
      .get<Guardian[]>(url)
      .pipe(map((res) => res.map((item: any) => new Guardian(item))));

    return lastValueFrom(source$);
  }

  formatDate(date) {
    if (date) {
      return moment(date).format('ddd, MMM DD hh:mm:ss');
    } else {
      return 'Not Completed';
    }
  }
}
