import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Transaction } from './transaction.model';
import { RouteService } from '../../../route.service';
import { OfflineTransaction } from '../../offlineTransactions/offlineTransaction.model';

import { ToastService } from '../../../toast.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class TransactionService {
  possibleBranches: any[] = [];
  possibleCashiers: any[] = [];
  transactions: Transaction[];
  offlineTransactions: OfflineTransaction[] = [];
  offset = 0;
  hasNext = false;
  hasPrev = false;

  baseUrl: string = environment.baseUrl;
  // At the start of the service the list of all
  // possible branches and cashiers is loaded for the filter dropdown selection
  constructor(
    private httpClient: HttpClient,
    private routeService: RouteService,
    public toastService: ToastService
  ) {}

  loadBranches() {
    const url: string = this.baseUrl + '/Branches/Admin/All';
    this.httpClient.get<any>(url).subscribe(
      (branches: any) => {
        this.possibleBranches = branches;
      },
      (error: any) => {
        console.error(error);
        this.routeService.checkErr(error).then((err: any) => {
          this.toastService.show('danger', 'Error', err);
        });
      }
    );
  }

  loadCashiers() {
    const url = this.baseUrl + '/Cashier/Admin/Cashiers';
    this.httpClient.get<any>(url).subscribe(
      (cashiers: any) => {
        this.possibleCashiers = cashiers;
      },
      (error) => {
        this.routeService.checkErr(error).then((err: any) => {
          console.error(err);
          this.toastService.show('danger', 'Error', err.err);
        });
      }
    );
  }

  // get data returns 50 transactions
  // from the offset the method also checks whether there are further transactions or not
  // By loading 51 if the loaded transaction size is 51 then further transactions exists
  getTransactions(filters: { [key: string]: any } = {}, limit, offset: number) {
    filters.limit = limit ? limit : 501;
    filters.offset = offset ? offset : this.offset;
    return new Promise((resolve, reject) => {
      const transactionArray: Transaction[] = [];
      const url: string = this.baseUrl + '/Transactions/Admin/All';
      this.httpClient
        .get<any>(url, {
          params: new HttpParams().appendAll(filters),
        })
        .subscribe(
          (transactions: any) => {
            //accounting for the extra object included in the streamed response
            transactions.pop();
            for (const transaction of transactions) {
              const tempTransaction: Transaction = new Transaction(transaction);
              transactionArray.push(tempTransaction);
            }
            this.transactions = transactionArray;
            if (this.transactions.length > filters.limit - 1) {
              this.hasNext = true;
              this.transactions.pop();
            } else {
              this.hasNext = false;
            }
            resolve(this.transactions);
          },
          (error) => {
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }

  getOfflineTransactions(filter: any) {
    return new Promise((resolve, reject) => {
      filter.limit = 51;
      filter.offset = this.offset;
      const transactionArray: OfflineTransaction[] = [];
      const url: string = this.baseUrl + '/Transactions/Admin/OfflineTransactions';
      const response = this.httpClient.post(url, filter);
      response.subscribe(
        (transactions: any) => {
          for (const transaction of transactions) {
            const tempTransaction: OfflineTransaction = new OfflineTransaction(
              transaction.transactionQueueID,
              transaction.transactionType,
              transaction.amount,
              transaction.timestamp,
              transaction.userID,
              transaction.branchID,
              transaction.shiftID,
              transaction.machineID,
              transaction.cashierID,
              transaction.connectionID,
              transaction.products,
              transaction.signature,
              transaction.status,
              transaction.reason,
              transaction.cashierName,
              transaction.branchName,
              transaction.studentName,
              transaction.organizationName,
              transaction.addedOn
            );
            transactionArray.push(tempTransaction);
          }
          this.offlineTransactions = transactionArray;
          if (this.offlineTransactions.length > 50) {
            this.hasNext = true;
            this.offlineTransactions.pop();
          } else {
            this.hasNext = false;
          }
          resolve(this.offlineTransactions);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }
}
