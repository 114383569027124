import * as moment from 'moment';

export class AttendanceReport {
  childID: number;
  fullName: string;
  className: string;
  grade: string;
  externalStudentID: string;
  isAbsent: boolean;
  isPresent: boolean;
  status: string;
  lastCheckout: moment.Moment;
  firstCheckIn: moment.Moment;
  firstCheckInGate: string;
  lastCheckoutGate: string;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.lastCheckout = obj.lastCheckout ? moment.utc(obj.lastCheckout) : null;
    this.firstCheckIn = obj.firstCheckIn ? moment.utc(obj.firstCheckIn) : null;
  }
}
