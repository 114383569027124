export class Merchant {
  merchantID: number;
  name: string;
  colorLogoURL: string;
  monochromeLogoURL: string;
  imageURL: string;
  color: string;
  darkUI: boolean;
  primaryCategoryID: number;
  secondaryCategoryID: number;
  phoneNumber: string;
  description: string;
  currencyCode: string;
  currencyPrecision: number;

  constructor(
    merchantID: number,
    name: string,
    colorLogoURL: string,
    monochromeLogoURL: string,
    imageURL: string,
    color: string,
    darkUI: boolean,
    primaryCategoryID: number,
    secondaryCategoryID: number,
    phoneNumber: string,
    description: string,
    currencyCode?: string,
    currencyPrecision?: number,
  ) {
    this.merchantID = merchantID;
    this.name = name;
    this.colorLogoURL = this.checkURL(colorLogoURL);
    this.monochromeLogoURL = this.checkURL(monochromeLogoURL);
    this.imageURL = this.checkURL(imageURL);
    this.color = color;
    this.darkUI = darkUI;
    this.primaryCategoryID = primaryCategoryID;
    this.secondaryCategoryID = secondaryCategoryID;
    this.phoneNumber = phoneNumber;
    this.description = description;
    this.currencyCode = currencyCode;
    this.currencyPrecision = currencyPrecision || 2;
  }

  checkURL(url: string) {
    if (url === null || url.startsWith('https') || url.startsWith('http')) {
      return url;
    } else {
      return 'https://' + url;
    }
  }
}
