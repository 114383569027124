import * as moment from 'moment';

export class OfflineTransaction {
  transactionQueueID: number;
  transactionType: string;
  amount: number;
  timestamp: string;
  userID: number;
  branchID: number;
  shiftID: number;
  machineID: number;
  cashierID: number;
  connectionID: number;
  products: any;
  signature: string;
  status: string;
  reason: string;
  cashierName: string;
  branchName: string;
  studentName: string;
  organizationName: string;
  addedOn: string;

  constructor(
    transactionQueueID: number,
    transactionType: string,
    amount: number,
    timestamp: string,
    userID: number,
    branchID: number,
    shiftID: number,
    machineID: number,
    cashierID: number,
    connectionID: number,
    products: any,
    signature: string,
    status: string,
    reason: string,
    cashierName: string,
    branchName: string,
    studentName: string,
    organizationName: string,
    addedOn: string
  ) {
    this.transactionQueueID = transactionQueueID;
    this.transactionType = transactionType;
    this.amount = amount;
    this.timestamp = this.getTimestamp(timestamp);
    this.userID = userID;
    this.branchID = branchID;
    this.shiftID = shiftID;
    this.machineID = machineID;
    this.cashierID = cashierID;
    this.connectionID = connectionID;
    this.products = products;
    this.signature = signature;
    this.status = status;
    this.reason = reason;
    this.cashierName = cashierName;
    this.branchName = branchName;
    this.studentName = studentName;
    this.organizationName = organizationName;
    this.addedOn = this.getTimestamp(addedOn);
  }

  getTimestamp(timestamp) {
    return moment(timestamp).format('Do MMMM YYYY, h:mm:ss a');
  }
}
