import * as moment from 'moment';

export class Shift {
  shiftID: number;
  username: string;
  cashierName: string;
  branchName: string;
  cashierID: number;
  startDate: string;
  endDate: string;
  startTime: any = {};
  endTime: any = {};
  duration: any = {};
  currencyCode: string;

  constructor(
    shiftID: number,
    username: string,
    cashierName: string,
    branchName: string,
    cashierID: number,
    startTime: string,
    endDate: string,
    currencyCode: string
  ) {
    this.shiftID = shiftID;
    this.username = username;
    this.cashierName = cashierName;
    this.branchName = branchName;
    this.cashierID = cashierID;
    this.startDate = startTime;
    this.endDate = endDate;
    this.currencyCode = currencyCode;
    this.processStartTime(startTime);
    this.processEndTime(endDate);
    this.calculateDuration();
  }

  processStartTime(time: string) {
    const timeArray: string[] = time.split('T');
    const date: string[] = timeArray[0].split('-');
    this.startTime.year = +date[0] - 2000;
    switch (date[1]) {
      case '01':
        this.startTime.month = 'January';
        break;
      case '02':
        this.startTime.month = 'February';
        break;
      case '03':
        this.startTime.month = 'March';
        break;
      case '04':
        this.startTime.month = 'April';
        break;
      case '05':
        this.startTime.month = 'May';
        break;
      case '06':
        this.startTime.month = 'June';
        break;
      case '07':
        this.startTime.month = 'July';
        break;
      case '08':
        this.startTime.month = 'August';
        break;
      case '09':
        this.startTime.month = 'September';
        break;
      case '10':
        this.startTime.month = 'October';
        break;
      case '11':
        this.startTime.month = 'November';
        break;
      case '12':
        this.startTime.month = 'December';
        break;
    }
    this.startTime.day = date[2];
    const hoursArray: string[] = timeArray[1].split(':');
    if (+hoursArray[0] > 12) {
      this.startTime.amPm = 'pm';
      if (+hoursArray[0] > 21) {
        this.startTime.hour = +hoursArray[0] - 12;
      } else {
        this.startTime.hour = '0' + (+hoursArray[0] - 12);
      }
    } else {
      this.startTime.amPm = 'am';
      this.startTime.hour = hoursArray[0];
      if (hoursArray[0] === '00') {
        this.startTime.hour = '12';
      }
    }
    this.startTime.minutes = hoursArray[1];
  }

  processEndTime(time: string) {
    if (this.endDate !== null) {
      const timeArray: string[] = time.split('T');
      const date: string[] = timeArray[0].split('-');
      this.endTime.year = +date[0] - 2000;
      switch (date[1]) {
        case '01':
          this.endTime.month = 'January';
          break;
        case '02':
          this.endTime.month = 'February';
          break;
        case '03':
          this.endTime.month = 'March';
          break;
        case '04':
          this.endTime.month = 'April';
          break;
        case '05':
          this.endTime.month = 'May';
          break;
        case '06':
          this.endTime.month = 'June';
          break;
        case '07':
          this.endTime.month = 'July';
          break;
        case '08':
          this.endTime.month = 'August';
          break;
        case '09':
          this.endTime.month = 'September';
          break;
        case '10':
          this.endTime.month = 'October';
          break;
        case '11':
          this.endTime.month = 'November';
          break;
        case '12':
          this.endTime.month = 'December';
          break;
      }
      this.endTime.day = date[2];
      const hoursArray: string[] = timeArray[1].split(':');
      if (+hoursArray[0] > 12) {
        this.endTime.amPm = 'pm';
        if (+hoursArray[0] > 21) {
          this.endTime.hour = +hoursArray[0] - 12;
        } else {
          this.endTime.hour = '0' + (+hoursArray[0] - 12);
        }
      } else {
        this.endTime.amPm = 'am';
        this.endTime.hour = hoursArray[0];
        if (hoursArray[0] === '00') {
          this.endTime.hour = '12';
        }
      }
      this.endTime.minutes = hoursArray[1];
    }
  }

  calculateDuration() {
    let end = moment(new Date());

    if (this.endDate !== null) {
      end = moment(this.endDate);
    }
    const start = moment(this.startDate); // another date
    const duration = moment.duration(end.diff(start));
    this.duration.hours = Math.floor(duration.asHours());
    this.duration.minutes = Math.floor(duration.asMinutes()) - 60 * this.duration.hours;
  }

  formatTime() {
    return moment(this.startDate).format('h:mm a');
  }

  formatEndTime() {
    return moment(this.endDate).format('h:mm a');
  }
}

export type ShiftsDetails = {
  [shiftID: number]: {
    tenders: {
      tenderID: number;
      tenderType: string;
      tenderTypeArabic: string;
      sales: number;
      returns: number;
      loadup: number;
      discounts: number;
    }[];
    totalSales: number;
    totalReturns: number;
    totalLoadups: number;
    totalDiscounts: number;
  };
};
