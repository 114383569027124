import { Injectable } from '@angular/core';

@Injectable()
export class PhoneValidatorService {
  private phoneRegExp = new RegExp('^01[0-9]{1}[0-9]{8}');

  validate(phone: string) {
    const matches = phone.match(this.phoneRegExp);
    console.log(matches);
    return matches && phone.length < 16;
  }

  countryCodeValidator(countryCode: string) {
    return !!countryCode && countryCode.startsWith('+');
  }
}
