<div class="AddMargin">
  <div class="row">
    <img src="../../../assets/images/logoSmall.svg" class="element-img" />
  </div>
  <div class="row">
    <h2 class="title">Forgot Password</h2>
  </div>
  <div class="row" *ngIf="pageNumber === 0">
    <h4 class="title">Enter your username and we’ll send you a code to reset your password</h4>
  </div>
  <div class="row" *ngIf="pageNumber === 0">
    <input
      id="usernameInputField"
      class="element"
      placeholder="Username"
      required
      [(ngModel)]="username"
      type="text"
      [disabled]="loading"
      (keyup.enter)="resetPassword()"
      nbInput
      shape="round"
      fullWidth />
  </div>
  <div class="row" *ngIf="pageNumber === 0 && !loading">
    <button
      nbButton
      hero
      status="primary"
      shape="round"
      class="element-button"
      (click)="resetPassword()"
      [disabled]="!valid()">
      Reset Password
    </button>
  </div>
  <div class="row" *ngIf="pageNumber === 1">
    <h4 class="title">Please enter the code we've sent to your email</h4>
  </div>
  <div class="row" *ngIf="pageNumber === 1">
    <input
      maxlength="6"
      id="codeInputField"
      class="element codeEntry"
      placeholder="Code"
      required
      [(ngModel)]="code"
      type="text"
      [disabled]="loading"
      (keyup.enter)="submitCode()"
      nbInput
      shape="round"
      fullWidth />
  </div>
  <div class="row" *ngIf="pageNumber === 1 && !loading">
    <button
      nbButton
      hero
      status="primary"
      shape="round"
      class="element-button"
      (click)="submitCode()">
      Continue
    </button>
  </div>
  <div class="row" *ngIf="pageNumber === 2">
    <h4 class="title">Please choose a new password</h4>
  </div>
  <div class="row" *ngIf="pageNumber === 2">
    <h4 class="subTitle">Password must contain at least 8 characters</h4>
  </div>
  <div class="row" *ngIf="pageNumber === 2">
    <input
      id="passwordInputField"
      class="element"
      placeholder="New Password"
      required
      [(ngModel)]="newPassword"
      type="password"
      [disabled]="loading"
      (keyup.enter)="newPasswordSubmit()"
      nbInput
      shape="round"
      fullWidth />
  </div>
  <div class="row" *ngIf="pageNumber === 2">
    <input
      class="element"
      placeholder="Confirm Password"
      required
      [(ngModel)]="confirmPassword"
      type="password"
      [disabled]="loading"
      (keyup.enter)="newPasswordSubmit()"
      nbInput
      shape="round"
      fullWidth />
  </div>
  <div class="row" *ngIf="pageNumber === 2 && !loading">
    <button
      nbButton
      hero
      status="primary"
      shape="round"
      class="element-button"
      (click)="newPasswordSubmit()">
      Set Password
    </button>
  </div>
  <div class="row" *ngIf="loading">
    <div class="loader"></div>
  </div>
  <div class="row backToLogin">
    <p class="clickable" (click)="returnBack()">Return Back To Login</p>
  </div>
</div>
