import { Machine } from './machine.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouteService } from '../../../route.service';
import { ToastService } from '../../../toast.service';
import { environment } from '../../../../environments/environment';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class MachinesService {
  possibleBranches = [];
  branchesIdMap = new Map();
  machines: Machine[] = [];
  selectedIndex: number;
  machinesId: number[];

  baseUrl: string = environment.baseUrl;
  constructor(
    private httpClient: HttpClient,
    private routeService: RouteService,
    private toastService: ToastService
  ) {}

  // load the data from the API also gets all the possible branches for the dropdown menu selector
  getMachines() {
    return new Promise((resolve, reject) => {
      const machinesArray: Machine[] = [];
      const machinesIdArray: number[] = [];
      const url = this.baseUrl + '/Machines/Admin/Machines';
      this.httpClient.get<any>(url).subscribe(
        (machines: any) => {
          for (const machine of machines) {
            const tempMachine: Machine = new Machine(
              machine.area,
              machine.branchID,
              machine.branchName,
              machine.deviceSerial,
              machine.isActive,
              machine.machineID,
              -1,
              ''
            );
            machinesArray.push(tempMachine);
            machinesIdArray.push(machine.machineID);
          }
          this.machines = machinesArray;
          this.machinesId = machinesIdArray;
          resolve(this.machines);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  // change the selected machine
  updateSelected(selectedIndex: number) {
    this.selectedIndex = this.machinesId.indexOf(selectedIndex);
  }

  getAllMachines() {
    return new Promise((resolve, reject) => {
      const url = this.baseUrl + '/Machines/Admin/AllMachines';
      const machinesIdArray: number[] = [];
      const machinesArray: Machine[] = [];
      this.httpClient.get<any>(url).subscribe(
        (machines: any) => {
          for (const machine of machines) {
            const tempMachine: Machine = new Machine(
              machine.area,
              machine.branchID,
              machine.branchName,
              machine.deviceSerial,
              machine.isActive,
              machine.machineID,
              machine.merchantID,
              machine.merchantName
            );
            machinesArray.push(tempMachine);
            machinesIdArray.push(machine.machineID);
          }
          this.machinesId = machinesIdArray;
          this.machines = machinesArray;
          resolve(this.machines);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  updateMachineSuper(active: boolean, branchID: number) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Machines/Admin/UpdateMachine';
      const response = this.httpClient.post(url, {
        machineID: this.machines[this.selectedIndex].machineID,
        isActive: active,
        branchID: branchID,
      });
      response.subscribe(
        () => {
          resolve('success');
          this.toastService.show('success', 'Success', 'Machine updated');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  addMachine(active: boolean, branchID: number, serial: string) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Machines/Admin/Add';
      const response = this.httpClient.post(url, {
        isActive: active,
        branchID: branchID,
        deviceSerial: serial,
      });
      response.subscribe(
        () => {
          resolve('success');
          this.toastService.show('success', 'Success', 'Machine added');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  deleteMachine(machineID: number) {
    const url = this.baseUrl + '/Machines/Admin/Delete/' + machineID;
    const source$ = this.httpClient.delete(url);

    return lastValueFrom(source$);
  }
}
