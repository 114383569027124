import { BillingItem } from './billingItem.model';

export class Bill {
  billItems: BillingItem[];
  totalSales = 0;
  totalEGPIn = 0;
  totalRewards = 0;
  totalReversals = 0;
  startDate: string;
  endDate: string;
  invoiceNumber: string;
  spareDue: string;
  merchantDue: string;
  beingDownloadedSpare: boolean;
  beingDownloadedMerchant: boolean;
  statusSpare: string;
  statusMerchant: string;
  currencyCode: string;

  constructor(billItems: BillingItem[]) {
    this.billItems = billItems;
    this.beingDownloadedSpare = false;
    this.beingDownloadedMerchant = false;
    let merchantPaidTimes = 0;
    let sparePaidTimes = 0;
    for (const billingItem of this.billItems) {
      this.totalSales = this.totalSales + billingItem.totalSalesValue;
      this.totalEGPIn = this.totalEGPIn + billingItem.EGPIn;
      this.totalReversals = this.totalReversals + billingItem.totalReversalValue;
      this.totalRewards = this.totalRewards + billingItem.totalRewardValue;
      if (billingItem.merchantPaidOn !== null) {
        merchantPaidTimes = merchantPaidTimes + 1;
      }
      if (billingItem.sparePaidOn !== null) {
        sparePaidTimes = sparePaidTimes + 1;
      }
    }
    if (sparePaidTimes === 0) {
      this.statusSpare = 'UNSETTLED';
    } else {
      if (sparePaidTimes === billItems.length) {
        this.statusSpare = 'SETTLED';
      } else {
        this.statusSpare = 'PARTIALLY SETTLED';
      }
    }
    if (merchantPaidTimes === 0) {
      this.statusMerchant = 'UNSETTLED';
    } else {
      if (merchantPaidTimes === billItems.length) {
        this.statusMerchant = 'SETTLED';
      } else {
        this.statusMerchant = 'PARTIALLY SETTLED';
      }
    }
    this.totalSales = +this.totalSales.toFixed(2);
    this.totalEGPIn = +this.totalEGPIn.toFixed(2);
    this.totalReversals = +this.totalReversals.toFixed(2);
    this.totalRewards = +this.totalRewards.toFixed(2);
    if (billItems.length > 0) {
      this.spareDue = billItems[0].spareToMerchantDue;
      this.merchantDue = billItems[0].merchantToSpareDue;
      this.startDate = billItems[0].startDate;
      this.endDate = billItems[0].endDate;
      this.invoiceNumber = billItems[0].invoiceNumber + '';
      this.currencyCode = billItems[0].currencyCode;
      while (this.invoiceNumber.length < 6) this.invoiceNumber = '0' + this.invoiceNumber;
    }
  }
}
