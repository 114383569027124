import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from './@core/utils/analytics.service';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'spare-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  constructor(private analytics: AnalyticsService, private primeConfig: PrimeNGConfig) {}

  ngOnInit() {
    this.analytics.trackPageViews();

    this.primeConfig.ripple = false;
    // this.primeConfig.filterMatchModeOptions = {
    //   text: ['contains', 'startsWith', 'endsWith', 'equals', 'notEquals'],
    //   number: ['equals', 'lessThan', 'greaterThan', 'notEquals', 'in', 'notIn'],
    //   date: ['equals', 'lessThan', 'greaterThan', 'notEquals', 'in', 'notIn'],
    // };
  }
}
