import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { VerifiedUser } from './user.model';
import { MerchantOrganizationService } from '../organizations/organization.service';
import { RouteService } from '../../../route.service';
import { ToastService } from '../../../toast.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class UsersService {
  offset = 0;
  hasNext = false;
  hasPrev = false;
  selectedUser: VerifiedUser;

  baseUrl: string = environment.baseUrl;
  constructor(
    private httpClient: HttpClient,
    private organizationService: MerchantOrganizationService,
    private routeService: RouteService,
    private toastService: ToastService
  ) {}

  /*
    Return 50 users at a time
    */
  getData(organizationID: number) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/VerifiedUsers/Admin/OrganizationID';
      const users: VerifiedUser[] = [];
      this.httpClient
        .get<any>(url, {
          params: new HttpParams()
            .set('limit', '51')
            .set('offset', '' + this.offset)
            .set('organizationID', '' + organizationID),
        })
        .subscribe(
          (usersData: any) => {
            for (const user of usersData) {
              const tempUser: VerifiedUser = new VerifiedUser(
                user.verifiedUserID,
                user.userID,
                user.organizationID,
                user.isBlocked,
                user.firstName,
                user.lastName,
                user.emailPostFix,
                user.externalPeripheralID
              );
              users.push(tempUser);
            }
            if (users.length > 50) {
              this.hasNext = true;
              users.pop();
            } else {
              this.hasNext = false;
            }
            resolve(users);
          },
          (error) => {
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }

  /*
Return all users
*/
  getAllData(organizationID: number) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/VerifiedUsers/Admin/OrganizationID';
      const users: VerifiedUser[] = [];
      this.httpClient
        .get<any>(url, {
          params: new HttpParams().set('organizationID', '' + organizationID),
        })
        .subscribe(
          (usersData: any) => {
            for (const user of usersData) {
              const tempUser: VerifiedUser = new VerifiedUser(
                user.verifiedUserID,
                user.userID,
                user.organizationID,
                user.isBlocked,
                user.firstName,
                user.lastName,
                user.emailPostFix,
                user.externalPeripheralID
              );
              users.push(tempUser);
            }
            resolve(users);
          },
          (error) => {
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }

  /*
    Get all the possible organizations linked to the user for the drop down selector
    */
  getPossibleOrganizations() {
    return new Promise((resolve, reject) => {
      this.organizationService.getOrganizations(false).then(
        (res: any) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  /*
    Update an existing user by calling on the api first to update the payroll limit then to update the status
    */
  updateUser(limit: number, status: string) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/VerifiedUsers/Admin/UpdatePayroll';
      const response = this.httpClient.post(url, {
        verifiedUserID: this.selectedUser.verifiedUserID,
        payrollLimit: limit,
        organizationID: this.selectedUser.organizationID,
      });
      response.subscribe(
        () => {
          const url: string = this.baseUrl + '/VerifiedUsers/Admin/UpdateStatus';
          const response = this.httpClient.post(url, {
            verifiedUserID: this.selectedUser.verifiedUserID,
            isBlocked: status === 'BLOCKED',
            organizationID: this.selectedUser.organizationID,
          });
          response.subscribe(
            () => {
              resolve('success');
              this.toastService.show('success', 'Success', 'User Updated Successfully');
            },
            (error) => {
              this.routeService.checkErr(error).then((err: any) => {
                reject(err);
                this.toastService.show('danger', 'Error', err.err);
              });
            }
          );
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  // Takes in a filter object which contains the filter criteria and search for transactions satisfying the criteria
  // Only 50 transactions starting from the offset are loaded at any time and the has next is checked every time
  filterUsers(filter: any) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/VerifiedUsers/Admin/OrganizationID';
      filter.limit = 51;
      filter.offset = this.offset;
      const usersArray: VerifiedUser[] = [];
      const response = this.httpClient.get(url, { params: filter });
      response.subscribe(
        (users: any) => {
          for (const user of users) {
            const tempUser: VerifiedUser = new VerifiedUser(
              user.verifiedUserID,
              user.userID,
              user.organizationID,
              user.isBlocked,
              user.firstName,
              user.lastName,
              user.emailPostFix,
              user.externalPeripheralID
            );
            usersArray.push(tempUser);
          }
          if (usersArray.length > 50) {
            this.hasNext = true;
            usersArray.pop();
          } else {
            this.hasNext = false;
          }
          resolve(usersArray);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }
}
