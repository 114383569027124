import { Merchant } from './merchant.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RouteService } from '../../route.service';

import { ToastService } from '../../toast.service';
import { environment } from '../../../environments/environment';
import { DateRange } from '../../ui/spare-components/smart-datepicker/date-range.model';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class DashboardService {
  currentMerchant: Merchant;

  baseUrl: string = environment.baseUrl;
  // Obtaining all the possible categories and their ids
  constructor(
    private httpClient: HttpClient,
    private routeService: RouteService,
    private toastService: ToastService
  ) {}

  // Calls on the API to get the data of the merchant
  getMerchant(): Promise<Merchant> {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Merchants/Admin/Current';
      this.httpClient.get<Merchant>(url).subscribe(
        (result: Merchant) => {
          this.currentMerchant = new Merchant(
            result[0].merchantID,
            result[0].name,
            result[0].colorLogoURL,
            result[0].monochromeLogoURL,
            result[0].imageURL,
            result[0].color,
            result[0].darkUI !== 0,
            result[0].primaryCategoryID,
            result[0].secondaryCategoryID,
            result[0].phoneNumber,
            result[0].description,
            result[0].currencyCode,
            result[0].currencyPrecision
          );
          resolve(this.currentMerchant);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  // Calls on the API to get the metrics according to the input period
  getPerformance(dateRange: DateRange, branchesID?: number[]) {
    const dateFrom = dateRange.startDate.unix() * 1000;
    const dateTo = dateRange.endDate.unix() * 1000;
    console.log('#DATE RNAGE IN SERVICE ', dateFrom, dateTo);

    const url = `${this.baseUrl}/Merchants/Admin/Metrics`;
    let params: HttpParams = new HttpParams()
      .set('from', dateFrom.toString())
      .set('to', dateTo.toString());

    if (branchesID) params = params.append('branchesID', JSON.stringify(branchesID));

    const response = this.httpClient.get(url, { params: params });

    return lastValueFrom(response);
  }

  updateMerchant(
    description: string,
    phone: string,
    color: string,
    category1: number,
    category2: number
  ) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Merchants/Admin/StoreUpdate';
      const response = this.httpClient.post(url, {
        color: color,
        primaryCategoryID: category1,
        secondaryCategoryID: category2,
        phoneNumber: phone,
        description: description,
      });
      response.subscribe(
        () => {
          resolve('success');
          this.toastService.show('success', 'Success', 'Store profile updated successfully');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }
}
