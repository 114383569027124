import { Component } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { _isNumberValue } from '@angular/cdk/coercion';

@Component({
  selector: 'spare-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  loggedIn = false;
  form = new FormGroup({
    username: new FormControl('', [Validators.required, Validators.minLength(4)]),
    password: new FormControl('', [Validators.required, Validators.minLength(4)]),
  });

  constructor(private authService: AuthService, private router: Router) {}

  // Calls on the login for the authservice if the login is successful then the user is redirected to the dashboard
  // If login is not successful then an error toast is displayed
  // In both cases the loggedIn boolean is toogled in order to display and hide the loader
  login() {
    if (!this.form.valid) return;

    this.loggedIn = true;
    this.authService.login(this.form.get('username').value, this.form.get('password').value).then(
      () => {
        if (
          (+localStorage.getItem('spareAdminRole') !== 1 &&
            localStorage.getItem('spareRoles') !== '' &&
            localStorage.getItem('spareMerchantID')) ||
          +localStorage.getItem('spareRoles').split(',') > 2
        ) {
          this.authService.fromLogin = true;
          this.router.navigate(['auth', 'change-view']);
        } else {
          this.router.navigate(['']);
        }
        this.loggedIn = false;
      },
      () => {
        this.loggedIn = false;
      }
    );
  }

  // Redirect to the forgot password screen
  forgotPassword() {
    this.router.navigate(['./auth/request-password']);
  }
}
