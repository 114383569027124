export class CreditCardRecharge {
  rechargeOrderNumber: string;
  organizationName: string;
  userID: number;
  rechargeAmount: number;
  rechargeFees: number;
  fortTransactionID: string;
  timestamp: Date;
  comments: string;
  providerName: string;

  constructor(
    rechargeOrderNumber: string,
    organizationName: string,
    userID: number,
    rechargeAmount: number,
    rechargeFees: number,
    fortTransactionID: string,
    timestamp: string,
    comments: string,
    providerName: string
  ) {
    this.rechargeOrderNumber = rechargeOrderNumber;
    this.organizationName = organizationName;
    this.userID = userID;
    this.rechargeAmount = rechargeAmount;
    this.rechargeFees = rechargeFees;
    this.fortTransactionID = fortTransactionID;
    this.timestamp = new Date(timestamp);
    this.comments = comments;
    this.providerName = providerName;
  }
}
