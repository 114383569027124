import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class NewsFeedGuard implements CanActivate {
  canActivate(
    _next?: ActivatedRouteSnapshot,
    _state?: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const role = +localStorage.getItem('spareSelectedOrganizationRole');
    return (role & (1 + 2 + 512 + 1024)) > 0;
  }
}
