import * as moment from 'moment';

export class PeripheralItem {
  peripheralID: number;
  className: string;
  connectedSince: string;
  division: string;
  externalPeripheralID: string;
  externalStudentID: string;
  fullName: string;
  grade: string;
  hardwareType: string;
  isPaired: boolean;
  isActive: boolean;
  serial: string;
  stage: string;
  status: string;
  userID: number;
  userFullName: string;
  registeredSince: string;

  constructor(peripheral) {
    this.peripheralID = peripheral.peripheralID;
    this.className = peripheral.className;
    this.connectedSince = moment(peripheral.connectedSince).format('YYYY-MM-DD HH:mm');
    this.division = peripheral.division;
    this.externalPeripheralID = peripheral.externalPeripheralID;
    this.externalStudentID = peripheral.externalStudentID;
    this.fullName = peripheral.fullName;
    this.grade = peripheral.grade;
    this.hardwareType = peripheral.hardwareType === 2 ? 'Wristband' : 'Card';
    this.isPaired = peripheral.isPaired === 1;
    this.isActive = peripheral.isActive === 1;
    this.serial = peripheral.serial;
    this.stage = peripheral.stage;
    this.userID = peripheral.userID;
    this.userFullName = peripheral.userFullName;
    this.registeredSince = moment(peripheral.registeredSince).format('YYYY-MM-DD HH:mm:ss');
    this.processStatus();
  }

  processStatus() {
    if (this.externalStudentID) {
      if (this.isPaired) {
        this.status = 'Paired';

        if (this.isActive) {
          this.status += ', Active';
        } else {
          this.status += ', Not Active';
        }
      } else {
        this.status = 'Not Paired'; // Linked, Not Paired
      }
    } else {
      this.status = 'Not Connected to Anyone';
    }
  }
}
