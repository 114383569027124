export class BillingItem {
  EGPIn: number;
  branchID: number;
  branchName: string;
  comissionDue: number;
  loans: number;
  organizationColor: string;
  organizationColorLogoURL: string;
  organizationID: number;
  organizationName: string;
  totalReversalValue: number;
  totalRewardValue: number;
  totalSalesValue: number;
  endDate: string;
  startDate: string;
  invoiceID: number;
  invoiceNumber: number;
  sparePaidOn: string;
  merchantPaidOn: string;
  spareToMerchantDue: string;
  merchantToSpareDue: string;
  organizationHandlesBilling: boolean;
  beingDownloadedMerchant: boolean;
  beingDownloadedSpare: boolean;
  currencyCode: string;

  constructor(
    EGPIn: number,
    branchID: number,
    branchName: string,
    comissionDue: number,
    loans: number,
    organizationColor: string,
    organizationColorLogoURL: string,
    organizationID: number,
    organizationName: string,
    totalReversalValue: number,
    totalRewardValue: number,
    totalSalesValue: number,
    endDate: string,
    startDate: string,
    invoiceID: number,
    invoiceNumber: number,
    sparePaidOn: string,
    merchantPaidOn: string,
    spareToMerchantDue: string,
    merchantToSpareDue: string,
    organizationHandlesBilling: boolean,
    currencyCode: string
  ) {
    this.EGPIn = EGPIn;
    this.branchID = branchID;
    this.branchName = branchName;
    this.comissionDue = comissionDue;
    this.loans = loans;
    this.organizationColor = organizationColor;
    this.organizationColorLogoURL = organizationColorLogoURL;
    this.organizationID = organizationID;
    this.organizationName = organizationName;
    this.totalReversalValue = totalReversalValue;
    this.totalRewardValue = totalRewardValue;
    this.totalSalesValue = totalSalesValue;
    this.endDate = endDate;
    this.startDate = startDate;
    this.invoiceID = invoiceID;
    this.invoiceNumber = invoiceNumber;
    this.sparePaidOn = sparePaidOn;
    this.merchantPaidOn = merchantPaidOn;
    this.spareToMerchantDue = spareToMerchantDue;
    this.merchantToSpareDue = merchantToSpareDue;
    this.organizationHandlesBilling = organizationHandlesBilling;
    this.currencyCode = currencyCode;
    this.beingDownloadedMerchant = false;
    this.beingDownloadedSpare = false;
  }
}
