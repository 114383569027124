export class PhoneVerifications {
  verificationID: number;
  phoneNumber: string;
  verificationRequestedAt: string;
  resendRequestedAt: string;
  verifiedAt: string;
  successfulLoginAt: string;

  constructor(
    verificationID: number,
    phoneNumber: string,
    verificationRequestedAt: string,
    resendRequestedAt: string,
    verifiedAt: string,
    successfulLoginAt: string
  ) {
    this.verificationID = verificationID;
    this.phoneNumber = phoneNumber;
    this.verificationRequestedAt = verificationRequestedAt;
    this.resendRequestedAt = resendRequestedAt;
    this.verifiedAt = verifiedAt;
    this.successfulLoginAt = successfulLoginAt;
  }
}
