import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { RouteService } from '../../route.service';
import { Bill } from './bill.model';
import { BillingItem } from './billingItem.model';
import { SuperBillItem } from './superadminBilling/superBillItem.model';
import { SuperBill } from './superadminBilling/superBill.model';
import { OrganizationBillItem } from './organizationBilling/organizationBillItem.model';
import { OrganizationBill } from './organizationBilling/organizationBill.model';
import { MerchantOrganizationService } from '../organization/organizations/organization.service';
import { Organization } from '../organization/organizations/organization.model';
import * as moment from 'moment';
import { CreditCardRecharge } from './creditCardRecharges/creditCardRecharge.model';

import { ToastService } from '../../toast.service';
import { environment } from '../../../environments/environment';
import { PaymentRequests } from './requests/models/PaymentRequests.model';

@Injectable()
export class BillingService {
  baseUrl: string = environment.baseUrl;

  constructor(
    private httpClient: HttpClient,
    private routeService: RouteService,
    private organizationService: MerchantOrganizationService,
    private toastService: ToastService
  ) { }

  getCurrentBill() {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Billing/CurrentWeek';
      this.httpClient.get<any>(url).subscribe(
        (result: any[]) => {
          const billItemsArray: BillingItem[] = [];
          for (const item of result) {
            const tempBillItem: BillingItem = new BillingItem(
              item.EGPIn,
              item.branchID,
              item.branchName,
              item.commisionDue,
              item.loans,
              item.organizationColor,
              item.organizationColorLogogURL,
              item.organizationID,
              item.organizationName,
              item.totalReversalValue,
              item.totalRewardValue,
              item.totalSalesValue,
              '',
              '',
              0,
              0,
              item.paidSpareToMerchant,
              item.paidMerchantToSpare,
              item.spareToMerchantDue,
              item.merchantToSpareDue,
              item.organizationHandlesBilling,
              item.currencyCode
            );
            billItemsArray.push(tempBillItem);
          }
          const bill: Bill = new Bill(billItemsArray);
          resolve(bill);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  getPastBills() {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Billing/All';
      this.httpClient.get<any>(url).subscribe(
        (result: any[]) => {
          const bills: Bill[] = [];
          let previousInvoiceNumber: number;
          if (result.length > 0) {
            previousInvoiceNumber = result[0].invoiceNumber;
          }
          let billItemsArray: BillingItem[] = [];
          for (const item of result) {
            const tempBillItem: BillingItem = new BillingItem(
              item.EGPIn,
              item.branchID,
              item.branchName,
              item.commisionDue,
              item.loans,
              item.organizationColor,
              item.organizationLogoURL,
              item.organizationID,
              item.organizationName,
              item.totalReversalValue,
              item.totalRewardValue,
              item.totalSalesValue,
              item.endDate,
              item.startDate,
              item.invoiceID,
              item.invoiceNumber,
              item.paidSpareToMerchant,
              item.paidMerchantToSpare,
              item.spareToMerchantDue,
              item.merchantToSpareDue,
              item.organizationHandlesBilling,
              item.currencyCode
            );
            if (tempBillItem.invoiceNumber !== previousInvoiceNumber) {
              bills.push(new Bill(billItemsArray));
              billItemsArray = [];
            }
            billItemsArray.push(tempBillItem);
            previousInvoiceNumber = tempBillItem.invoiceNumber;
          }
          if (billItemsArray.length > 0) {
            bills.push(new Bill(billItemsArray));
          }
          resolve(bills);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  getAllBillsMerchant() {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Billing/SuperAll';
      this.httpClient.get<any>(url).subscribe(
        (result: any[]) => {
          const bills: SuperBill[] = [];
          let previousInvoiceNumber: number;
          if (result.length > 0) {
            previousInvoiceNumber = result[0].invoiceNumber;
          }
          let billItemsArray: SuperBillItem[] = [];
          for (const item of result) {
            const tempBillItem: SuperBillItem = new SuperBillItem(
              item.invoiceID,
              item.EGPIn,
              item.totalSalesValue,
              item.comissionDue,
              item.loans,
              item.totalReversalValue,
              item.totalRewardValue,
              item.branchID,
              item.invoiceNumber,
              item.startDate,
              item.endDate,
              item.merchantToSpareDue,
              item.spareToMerchantDue,
              item.paidMerchantToSpare,
              item.paidSpareToMerchant,
              item.organizationID,
              item.organizationName,
              item.organizationLogoURL,
              item.branchName,
              item.merchantID,
              item.merchantName,
              item.organizationHandlesBilling
            );
            if (tempBillItem.invoiceNumber !== previousInvoiceNumber) {
              bills.push(new SuperBill(billItemsArray));
              billItemsArray = [];
            }
            billItemsArray.push(tempBillItem);
            previousInvoiceNumber = tempBillItem.invoiceNumber;
          }
          if (billItemsArray.length > 0) {
            bills.push(new SuperBill(billItemsArray));
          }
          resolve(bills);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  getAllBillsOrganization() {
    return new Promise((resolve, reject) => {
      let organizations: Organization[] = [];
      this.organizationService.getOrganizations(false).then(
        (organizationsData: Organization[]) => {
          organizations = organizationsData;
          const url: string = this.baseUrl + '/Billing/SuperAll';
          this.httpClient.get<any>(url).subscribe(
            (result: any[]) => {
              const organizationIndexMap = new Map();
              let index = 0;
              for (const organization of organizations) {
                organizationIndexMap.set(organization.organizationID, index);
                index = index + 1;
              }
              const bills: SuperBill[] = [];
              let previousStartDate: string;
              if (result.length > 0) {
                previousStartDate = result[0].invoiceNumber;
              }
              let billsArray: SuperBillItem[][] = Array(organizations.length).fill([]);

              for (const item of result) {
                const tempBillItem: SuperBillItem = new SuperBillItem(
                  item.invoiceID,
                  item.EGPIn,
                  item.totalSalesValue,
                  item.comissionDue,
                  item.loans,
                  item.totalReversalValue,
                  item.totalRewardValue,
                  item.branchID,
                  item.invoiceNumber,
                  item.startDate,
                  item.endDate,
                  item.merchantToSpareDue,
                  item.spareToMerchantDue,
                  item.paidMerchantToSpare,
                  item.paidSpareToMerchant,
                  item.organizationID,
                  item.organizationName,
                  item.organizationLogoURL,
                  item.branchName,
                  item.merchantID,
                  item.merchantName,
                  item.organizationHandlesBilling
                );
                if (tempBillItem.startDate !== previousStartDate) {
                  for (const billsArrayItem of billsArray) {
                    if (billsArrayItem.length > 0) {
                      bills.push(new SuperBill(billsArrayItem));
                    }
                  }
                  billsArray = Array(organizations.length).fill([]);
                }
                if (tempBillItem.organizationID !== null) {
                  billsArray[organizationIndexMap.get(tempBillItem.organizationID)].push(
                    tempBillItem
                  );
                  previousStartDate = tempBillItem.startDate;
                }
              }
              if (billsArray.length > 0) {
                for (const bill of billsArray) {
                  if (bill.length > 0) {
                    bills.push(new SuperBill(bill));
                  }
                }
              }
              resolve(bills);
            },
            (error) => {
              this.routeService.checkErr(error).then((err: any) => {
                reject(err);
                this.toastService.show('danger', 'Error', err.err);
              });
            }
          );
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getCurrentOrganizationBill() {
    const url: string = this.baseUrl + '/Billing/CurrentWeekOrg';
    const billItems: OrganizationBillItem[] = [];
    return new Promise((resolve, reject) => {
      const orgID = localStorage.getItem('spareSelectedOrganization');
      this.httpClient
        .get<any>(url, {
          params: new HttpParams().set('organizationID', '' + orgID),
        })
        .subscribe(
          (billData: any) => {
            for (const billItem of billData) {
              const tempBillItem: OrganizationBillItem =
                this.getOrgBillItemFromServerResponse(billItem);
              billItems.push(tempBillItem);
            }
            resolve(new OrganizationBill(billItems));
          },
          (error) => {
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }

  getPastBillsOrganization() {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Billing/AllOrg';
      this.httpClient
        .get<any>(url, {
          params: new HttpParams().set(
            'organizationID',
            '' + localStorage.getItem('spareSelectedOrganization')
          ),
        })
        .subscribe(
          (result: any[]) => {
            const bills: OrganizationBill[] = [];
            let previousStartDate: string;
            if (result.length > 0) {
              previousStartDate = result[0].startDate;
            }
            let billItemsArray: OrganizationBillItem[] = [];
            for (const billItem of result) {
              const tempBillItem: OrganizationBillItem =
                this.getOrgBillItemFromServerResponse(billItem);
              if (tempBillItem.startDate !== previousStartDate) {
                bills.push(new OrganizationBill(billItemsArray));
                billItemsArray = [];
              }
              billItemsArray.push(tempBillItem);
              previousStartDate = tempBillItem.startDate;
            }
            if (billItemsArray.length > 0) {
              bills.push(new OrganizationBill(billItemsArray));
            }
            resolve(bills);
          },
          (error) => {
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }

  setBillToPaidOrganization(item: OrganizationBillItem, operation: string) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Billing/SetInvoicePaidOrganization';
      this.httpClient
        .post(url, {
          invoiceID: item.invoiceID,
          type: operation,
          organizationID: item.organizationID,
        })
        .subscribe(
          () => {
            resolve('success');
            this.toastService.show('success', 'Success', 'Bill item set to paid successfully');
          },
          (error) => {
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }

  setBillToPaidSuperAdmin(item: SuperBillItem, operation: string, date: Date) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Billing/SetInvoicePaid';
      this.httpClient
        .post(url, {
          invoiceID: item.invoiceID,
          type: operation,
          date: moment(date).format('YYYY-MM-DD'),
        })
        .subscribe(
          () => {
            resolve('success');
            this.toastService.show('success', 'Success', 'Bill item set to paid successfully');
          },
          (error) => {
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }

  setBillToUnpaidSuperAdmin(item: SuperBillItem, operation: string) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Billing/SetInvoiceUnpaid';
      this.httpClient
        .post(url, {
          invoiceID: item.invoiceID,
          type: operation,
        })
        .subscribe(
          () => {
            resolve('success');
            this.toastService.show('success', 'Success', 'Bill item set to unpaid successfully');
          },
          (error) => {
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }

  checkCreditCardTransactionStatus(merchantOrderNumber) {
    return new Promise((resolve, reject) => {
      const url = `${this.baseUrl}/payfort/check-status`;
      const body = {
        merchantReference: merchantOrderNumber,
      };

      this.httpClient.post(url, body).subscribe(
        (result) => {
          return resolve(result);
        },
        (err) => {
          this.routeService.checkErr(err).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  getCreditCardRecharges(dateRange: any, success: boolean, isMerchantView: boolean): Promise<CreditCardRecharge[]> {
    let dateFrom = null,
      dateTo = null;
    if (dateRange.startDate && dateRange.endDate) {
      dateFrom = moment(dateRange.startDate).format('YYYY-MM-DD HH:mm:ss');
      dateTo = moment(dateRange.endDate).format('YYYY-MM-DD HH:mm:ss');
    }
    return new Promise((resolve, reject) => {
      const url = this.baseUrl + '/Transactions/Admin/CreditCardRecharges';

      const postBody = {
        dateFrom,
        dateTo,
        status: success,
        isMerchantView
      };
      this.httpClient.post(url, postBody).subscribe(
        (creditRechargesData: any[]) => {
          const creditRecharges: CreditCardRecharge[] = [];
          console.log('creditRechargesData', creditRechargesData);
          for (const creditRecharge of creditRechargesData) {
            const tempCreditCardRecharge = new CreditCardRecharge(
              creditRecharge.rechargeOrderNumber,
              creditRecharge.organizationName,
              creditRecharge.userID,
              creditRecharge.rechargeAmount,
              creditRecharge.rechargeFees,
              creditRecharge.fortTransactionID,
              moment(creditRecharge.timestamp).format('MMMM DD YYYY, h:mm A'),
              creditRecharge.comments,
              creditRecharge.providerName
            );
            creditRecharges.push(tempCreditCardRecharge);
          }
          resolve(creditRecharges);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }
  getOrgBillItemFromServerResponse(billItem: any): OrganizationBillItem {
    return {
      merchantID: billItem.merchantID,
      merchantName: billItem.merchantName,
      branchID: billItem.branchID,
      branchName: billItem.branchName,
      organizationName: billItem.organizationName,
      organizationID: billItem.organizationID,
      organizationColor: billItem.organizationColor,
      organizationHandlesBilling: billItem.organizationHandlesBilling,
      invoiceID: billItem.invoiceID,
      EGPIn: billItem.EGPIn,
      totalSalesValue: billItem.totalSalesValue,
      comissionDue: billItem.commisionDue,
      loans: billItem.loans,
      totalReversalValue: billItem.totalReversalValue,
      totalRewardValue: billItem.totalRewardValue,
      invoiceNumber: billItem.invoiceNumber,
      startDate: billItem.startDate,
      endDate: billItem.endDate,
      merchantToSpareDue: billItem.merchantToSpareDue,
      spareToMerchantDue: billItem.spareToMerchantDue,
      merchantPaidOn: billItem.paidMerchantToSpare,
      sparePaidOn: billItem.paidSpareToMerchant,
      logoURL: billItem.organizationLogoURL,
      totalOverdraft: billItem.totalOverdraft ? billItem.totalOverdraft : 0,
      totalOverdraftRepaid: billItem.totalOverdraftRepaid ? billItem.totalOverdraftRepaid : 0,
      totalOverdraftFees: billItem.totalOverdraftFees ? billItem.totalOverdraftFees : 0,
      currencyCode: billItem.currencyCode,
    };
  }

  getOverview(organizationID) {
    return new Promise((resolve, reject) => {
      const url = this.baseUrl + '/Billing/Overview?organizationID=' + organizationID;
      this.httpClient.get(url).subscribe(
        (res) => {
          console.log(res);
          resolve(res[0]);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  getAllPaymentRequest(organizationID: number, requestType: string) {
    return new Promise((resolve, reject) => {
      const url =
        this.baseUrl +
        '/payment-request/admin/all?organizationID=' +
        organizationID +
        '&requestType=' +
        requestType;

      this.httpClient.get<any>(url).subscribe(
        (res) => {
          const data: PaymentRequests[] = [];
          for (const item of res) {
            const tempPaymentReq: PaymentRequests = new PaymentRequests(
              item.paymentRequestID,
              item.adminID,
              item.createdByAdminName,
              item.parentName,
              item.childID,
              item.childName,
              item.userID,
              item.organizationID,
              item.requestReason,
              item.requestAmount,
              item.requestType,
              item.requestedOn ? item.requestedOn : null,
              item.cancelledOn ? item.cancelledOn : null,
              item.cancellationReason ? item.cancellationReason.toString() : null,
              item.cancelledByAdminID ? item.cancelledByAdminID : null,
              item.cancelledByAdminName ? item.cancelledByAdminName : null,
              item.lastNotified ? item.lastNotified : null,
              item.numberOfTimesNotified,
              item.paidOn ? item.paidOn : null,
              item.transactionReference ? item.transactionReference.toString() : null,
              item.rejectedOn ? item.rejectedOn : null,
              item.rejectionReason ? item.rejectionReason : null,
              item.refundedOn ? item.refundedOn : null,
              item.refundReason ? item.refundReason : null,
              item.refundedByAdminID ? item.refundedByAdminID : null,
              item.refundedByAdminName ? item.refundedByAdminName : null,
              item.refundTransactionReference ? item.refundTransactionReference.toString() : null,
              item.balance ? item.balance : 0,
              item.currencyCode,
              item.currencyLongName,
              item.branchName,
              item.externalStudentID
            );
            data.push(tempPaymentReq);
          }
          resolve(data);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  addPaymentRequest(data) {
    return new Promise((resolve, reject) => {
      const url = this.baseUrl + '/payment-request/admin/add';

      this.httpClient.post<any>(url, data).subscribe(
        () => {
          resolve('success');
          this.toastService.show('success', 'Success', 'Request Added Successfully');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  cancelPaymentRequest(organizationID: number, requestID: number, cancellationReason: string) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        organizationID: organizationID,
        paymentRequestID: requestID,
        cancellationReason: cancellationReason,
      },
    };
    return new Promise((resolve, reject) => {
      const url = this.baseUrl + '/payment-request/admin/cancel';

      this.httpClient.delete<any>(url, options).subscribe(
        () => {
          resolve('success');
          this.toastService.show('success', 'Success', 'Request Cancelled Successfully');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  refundPaymentRequest(organizationID: number, requestID: number, refundReason: string) {
    return new Promise((resolve, reject) => {
      const url = this.baseUrl + '/payment-request/admin/refund';

      this.httpClient
        .post<any>(url, {
          organizationID: organizationID,
          paymentRequestID: requestID,
          refundReason: refundReason,
        })
        .subscribe(
          () => {
            resolve('success');
            this.toastService.show('success', 'Success', 'Request Refunded Successfully');
          },
          (error) => {
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }

  notifyParent(organizationID: number, requestID: number) {
    return new Promise((resolve, reject) => {
      const url = this.baseUrl + '/payment-request/admin/notify';

      this.httpClient
        .post<any>(url, {
          organizationID: organizationID,
          paymentRequestID: requestID,
        })
        .subscribe(
          () => {
            resolve('success');
            this.toastService.show('success', 'Success', 'Parent Notified');
          },
          (error) => {
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }

  forcePaymentRequest(requestID: number) {
    return new Promise((resolve, reject) => {
      const url = this.baseUrl + '/payment-request/admin/force';

      this.httpClient
        .post<any>(url, {
          paymentRequestID: requestID,
        })
        .subscribe(
          () => {
            resolve('success');
            this.toastService.show('success', 'Success', 'Request Completed');
          },
          (error) => {
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }
}
