export class Admin {
  adminID: number;
  name: string;
  username: string;
  role: number;
  merchantID: number;
  email: string;
  phoneNumber: string;
  roleString: string;
  merchantName: string;

  constructor(
    adminID: number,
    name: string,
    username: string,
    role: number,
    merchantID: number,
    email: string,
    phoneNumber: string,
    merchantName: string
  ) {
    this.adminID = adminID;
    this.name = name;
    this.username = username;
    this.role = role;
    this.merchantID = merchantID;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.merchantName = merchantName;
    switch (this.role) {
      case 0:
        this.roleString = 'Super Admin';
        break;
      case 1:
        this.roleString = 'Store Admin';
        break;
      case 2:
        this.roleString = 'Spare Support';
        break;
      case 3:
        this.roleString = 'Store View';
        break;
      default:
        break;
    }
  }
}

export class OrganizationAdmin extends Admin {
  organizationAdminID: number;

  constructor(
    adminID: number,
    name: string,
    username: string,
    role: number,
    merchantID: number,
    email: string,
    phoneNumber: string,
    merchantName: string,
    organizationAdminID: number
  ) {
    super(adminID, name, username, role, merchantID, email, phoneNumber, merchantName);
    this.organizationAdminID = organizationAdminID;
  }
}
