import 'moment-timezone';
import * as moment from 'moment';

export class DateUtils {
  constructor(private organizationTimezoneDBName: string) {}

  stringToMomentAdapter(dateAsString: string, format?: string): moment.Moment {
    const momentDate = format ? moment(dateAsString) : moment(dateAsString, format);
    return momentDate;
  }

  formatMomentToOrganizationTimeZone(date: moment.Moment): moment.Moment {
    return date.tz(this.organizationTimezoneDBName, false);
  }

  formatStringUTCDateToOrganizationTimeZone(
    dateAsString: string,
    format?: string
  ): moment.Moment | null {
    return dateAsString
      ? this.formatMomentToOrganizationTimeZone(this.stringToMomentAdapter(dateAsString, format))
      : null;
  }

  // Format string date with format
  // Consider the date and time here in the organization time zone.
  formatStringDateInOrganizationTimezone(
    dateAsString: string,
    format?: string
  ): moment.Moment | null {
    const momentDate = moment.tz(dateAsString, format, this.organizationTimezoneDBName);
    return dateAsString ? momentDate : null;
  }
}
