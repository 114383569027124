import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ToastService } from '../../../toast.service';
import { Observable } from 'rxjs';

@Injectable()
export class BusSystemGuard implements CanActivate {
  constructor(private router: Router, private toastService: ToastService) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const orgRole: number = +localStorage.getItem('spareSelectedOrganizationRole');
    if (orgRole & (1 + 2 + 4096 + 8192)) {
      return true;
    } else {
      this.router.navigate(['pages']).then();
      this.toastService.show(
        'danger',
        "You don't have the permission to view this content",
        '',
        5000
      );
      return false;
    }
  }
}
