import * as moment from 'moment';

export class SuperBillItem {
  invoiceID: number;
  EGPIn: number;
  totalSalesValue: number;
  comissionDue: number;
  loans: number;
  totalReversalValue: number;
  totalRewardValue: number;
  branchID: number;
  invoiceNumber: number;
  startDate: string;
  endDate: string;
  merchantToSpareDue: string;
  spareToMerchantDue: string;
  merchantPaidOn: string;
  sparePaidOn: string;
  organizationID: number;
  organizationName: string;
  logoURL: string;
  branchName: string;
  merchantID: number;
  merchantName: string;
  organizationHandlesBilling: boolean;
  handlesBilling: string;
  initialMerchantPaidOn: string;
  initialSparePaidOn: string;

  constructor(
    invoiceID: number,
    EGPIn: number,
    totalSalesValue: number,
    comissionDue: number,
    loans: number,
    totalReversalValue: number,
    totalRewardValue: number,
    branchID: number,
    invoiceNumber: number,
    startDate: string,
    endDate: string,
    merchantToSpareDue: string,
    spareToMerchantDue: string,
    paidMerchantToSpare: string,
    paidSpareToMerchant: string,
    organizationID: number,
    organizationName: string,
    logoURL: string,
    branchName: string,
    merchantID: number,
    merchantName: string,
    organizationHandlesBilling: boolean
  ) {
    this.invoiceID = invoiceID;
    this.EGPIn = EGPIn;
    this.totalSalesValue = totalSalesValue;
    this.comissionDue = comissionDue;
    this.loans = loans;
    this.totalReversalValue = totalReversalValue;
    this.totalRewardValue = totalRewardValue;
    this.branchID = branchID;
    this.invoiceNumber = invoiceNumber;
    this.startDate = startDate;
    this.endDate = endDate;
    this.merchantToSpareDue = merchantToSpareDue;
    this.spareToMerchantDue = spareToMerchantDue;
    this.merchantPaidOn = paidMerchantToSpare;
    this.sparePaidOn = paidSpareToMerchant;
    this.organizationID = organizationID;
    this.organizationName = organizationName;
    this.logoURL = logoURL;
    this.branchName = branchName;
    this.merchantID = merchantID;
    this.merchantName = merchantName;
    this.organizationHandlesBilling = organizationHandlesBilling;
    if (organizationHandlesBilling) {
      this.handlesBilling = this.organizationName;
    } else {
      this.handlesBilling = 'Spare';
    }
    if (this.sparePaidOn !== null) {
      this.sparePaidOn = moment(this.sparePaidOn).format('YYYY-MM-DD');
    }
    if (this.merchantPaidOn !== null) {
      this.merchantPaidOn = moment(this.merchantPaidOn).format('YYYY-MM-DD');
    }
    this.initialMerchantPaidOn = this.merchantPaidOn;
    this.initialSparePaidOn = this.sparePaidOn;
  }
}
