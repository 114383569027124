import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '../../toast.service';
import { environment } from '../../../environments/environment';
import { Override } from './override.model';

@Injectable()
export class OverrideService {
  constructor(private httpClient: HttpClient, private toastService: ToastService) {}

  baseUrl: string = environment.baseUrl;
  orgID;
  data;

  getOverride() {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/override-peripheral/admin/?organizationID=' + this.orgID;
      const overrideArray: Override[] = [];
      this.httpClient.get<any>(url).subscribe(
        (res: any) => {
          for (const item of res) {
            const tempOverride: Override = new Override(
              item.externalPeripheralID,
              item.registeredOn,
              item.overridePeripheralID,
              item.isActive,
              item.canChargeOrganization,
              item.issuingOrganization,
              item.overridePeripheralName,
              item.removedOn
            );
            overrideArray.push(tempOverride);
          }
          console.log(res);
          resolve(overrideArray);
        },
        (error) => {
          console.log(error);
          reject(error);
          this.toastService.show('danger', 'Error', error.err);
        }
      );
    });
  }

  updatePeripheral(overridePeripheralID, isActive, canChargeOrganization) {
    const organizationID = this.orgID;
    return new Promise((resolve, reject) => {
      const url = this.baseUrl + '/override-peripheral/admin/Update';
      this.httpClient
        .post(url, {
          overridePeripheralID,
          isActive,
          canChargeOrganization,
          organizationID,
        })
        .subscribe(
          (res) => {
            console.log(res);
            resolve(res);
            this.toastService.show('success', 'Success', 'Peripheral Updated');
          },
          (error) => {
            console.log(error);
            reject(error);
            this.toastService.show('danger', 'Error', error.err);
          }
        );
    });
  }

  addOverrideCard(externalPeripheralID, overridePeripheralName, canChargeOrganization) {
    const organizationID = this.orgID;
    return new Promise((resolve, reject) => {
      const url = this.baseUrl + '/override-peripheral/admin';
      this.httpClient
        .post(url, {
          externalPeripheralID,
          overridePeripheralName,
          canChargeOrganization,
          organizationID,
        })
        .subscribe(
          (res) => {
            console.log(res);
            resolve(res);
            this.toastService.show('success', 'Success', 'Peripheral Added');
          },
          (error) => {
            console.log(error);
            reject(error);
            this.toastService.show('danger', 'Error', error.err);
          }
        );
    });
  }

  unPairOverride(overridePeripheralID) {
    const organizationID = this.orgID;
    const httpOptions = {
      headers: { 'Content-Type': 'application/json' },
      body: { overridePeripheralID, organizationID },
    };
    return new Promise((resolve, reject) => {
      const url = this.baseUrl + '/override-peripheral/admin';
      this.httpClient.delete(url, httpOptions).subscribe(
        (res) => {
          resolve(res);
          this.toastService.show('success', 'Success', 'Peripheral Deleted');
        },
        (error) => {
          console.log(error);
          reject(error);
          this.toastService.show('danger', 'Error', error.err);
        }
      );
    });
  }

  getTransactions(overridePeripheralID) {
    return new Promise((resolve, reject) => {
      const url: string =
        this.baseUrl +
        '/organization-transaction/?organizationID=' +
        this.orgID +
        '&overridePeripheralID=' +
        overridePeripheralID;
      this.httpClient.get<any>(url).subscribe(
        (res: any) => {
          resolve(res);
        },
        (error) => {
          console.log(error);
          reject(error);
          this.toastService.show('danger', 'Error', error.err);
        }
      );
    });
  }

  getFailedTransactions(overridePeripheralID) {
    return new Promise((resolve, reject) => {
      const url: string =
        this.baseUrl +
        '/failed-transactions/admin/override/?organizationID=' +
        this.orgID +
        '&overridePeripheralID=' +
        overridePeripheralID;
      this.httpClient.get<any>(url).subscribe(
        (res: any) => {
          console.log(res);
          resolve(res);
        },
        (error) => {
          console.log(error);
          reject(error);
          this.toastService.show('danger', 'Error', error.err);
        }
      );
    });
  }
}
