import * as moment from 'moment';
import { UserBill, UserBillItem } from './support-table-models/userBill.model';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouteService } from '../../route.service';
import { ToastService } from '../../toast.service';
import { environment } from '../../../environments/environment';
import { lastValueFrom, throwError } from 'rxjs';

@Injectable()
export class SupportService {
  baseUrl: string = environment.baseUrl;

  constructor(
    private httpClient: HttpClient,
    private routeService: RouteService,
    private toastService: ToastService
  ) {}

  findUser(searchBy: string, param: any): Promise<any[]> {
    const payload: object = {};
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/support/Admin';
      payload[searchBy] = param;
      const response = this.httpClient.post<any>(url, payload);
      response.subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  listUserPhoneChanges(userID: number): Promise<any[]> {
    const url: string = this.baseUrl + '/support/user-phone-changes/' + userID;
    const response = this.httpClient.get<any>(url);
    return lastValueFrom(response);
  }

  verifyEmail(userID: number) {
    return new Promise((resolve, reject) => {
      const payload: object = {};
      const url: string = this.baseUrl + '/support/manuallyVerifyEmail';
      payload['userID'] = userID;
      const response = this.httpClient.put<any>(url, payload);
      response.subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  unVerifyEmail(userID: number) {
    const payload: object = {};
    const url: string = this.baseUrl + '/support/manually-unverify-email';
    payload['userID'] = userID;
    const response = this.httpClient.put<any>(url, payload);

    return lastValueFrom(response);
  }
  updateName(newFirstName: string, newLastName: string, userID: number) {
    const payload: object = {};
    const url: string = this.baseUrl + '/support/update-user-name';
    payload['userID'] = userID;
    payload['newFirstName'] = newFirstName;
    payload['newLastName'] = newLastName;

    const response = this.httpClient.put<any>(url, payload);

    return lastValueFrom(response);
  }
  changePhoneNumber({ newCountryCode, newPhone, userID }) {
    const payload: object = {};
    const url: string = this.baseUrl + '/support/update-user-number';
    payload['userID'] = userID;
    payload['newPhoneNumber'] = newPhone;
    payload['newCountryCode'] = newCountryCode;

    const response = this.httpClient.put<any>(url, payload);

    return lastValueFrom(response);
  }

  getUserBillsForSupport(transactionRef: string, type: string, userID: number): Promise<UserBill> {
    const url = `${this.baseUrl}/user-bill/Admin/support/?userID=${userID}&${type === 'Redemption' ? 'transactionReference=' + transactionRef : 'returnTransactionReference=' + transactionRef}`;
    return this.httpClient
      .get(url)
      .pipe(catchError(this._errorHandler.bind(this)), map(_parseUserBill))
      .toPromise();

    /**
     * Get the response from end as UserBill strong typed
     * @param bill {any}
     * @return {UserBill}
     */
    function _parseUserBill(bill: any): UserBill {
      if (!bill) {
        return bill;
      }
      const billCopy: any = { ...bill };
      billCopy.isOnlineBranch = !!billCopy.isOnlineBranch;
      billCopy.billDate = moment(billCopy.billDate).format("MMM DD, YYYY hh:mm:ss a");
      billCopy.items = billCopy.items.map((item: UserBillItem) => {
        const itemCopy: UserBillItem = { ...item };
        if (itemCopy.deliveryDate) itemCopy.deliveryDate = moment(itemCopy.deliveryDate);
        return itemCopy;
      });

      return billCopy as UserBill;
    }
  }

  /**
   * Parsing error using route service and then show the error toast.
   * @param error
   * @private
   */
  private _errorHandler(error) {
    this.routeService.checkErr(error).then((err: any) => {
      this.toastService.show('danger', 'Error', err.err);
    });
    return throwError(error);
  }
}
