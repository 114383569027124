import * as moment from 'moment';
export class Override {
  externalPeripheralID: string;
  registeredOn: string;
  overridePeripheralID: number;
  isActive: number;
  canChargeOrganization: number;
  issuingOrganization: number;
  overridePeripheralName: string;
  removedOn: string;

  constructor(
    externalPeripheralID: string,
    registeredOn: string,
    overridePeripheralID: number,
    isActive: number,
    canChargeOrganization: number,
    issuingOrganization: number,
    overridePeripheralName: string,
    removedOn: string
  ) {
    this.externalPeripheralID = externalPeripheralID;
    this.registeredOn = this.formatDate(registeredOn);
    this.overridePeripheralID = overridePeripheralID;
    this.isActive = isActive;
    this.canChargeOrganization = canChargeOrganization;
    this.issuingOrganization = issuingOrganization;
    this.overridePeripheralName = overridePeripheralName;
    this.removedOn = removedOn ? this.formatDate(removedOn) : null;
  }

  formatDate(date) {
    return moment(date).format('DD MMM YYYY');
  }
}
