export class Section {
  sectionID: number;
  sectionName: string;
  merchantID: number;
  iconURL: string;
  sectionOrder: number;
  collapsed: boolean; // To display or hide the products under the section

  constructor(
    sectionID: number,
    sectionName: string,
    merchantID: number,
    iconURL: string,
    sectionOrder: number
  ) {
    this.sectionID = sectionID;
    this.sectionName = sectionName;
    this.merchantID = merchantID;
    this.iconURL = this.checkURL(iconURL);
    this.sectionOrder = sectionOrder;
    this.collapsed = true;
  }

  checkURL(url: string) {
    if (url === null || url.startsWith('https') || url.startsWith('http')) {
      return url;
    } else {
      return 'https://' + url;
    }
  }
}
