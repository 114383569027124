<div class="row">
  <img src="../../../assets/images/logoSmall.svg" class="element-img" />
</div>
<div class="row pb-4">
  <h2 *ngIf="!fromLogin" class="title">Switch View</h2>
  <h2 *ngIf="fromLogin" class="title">Select View</h2>
</div>
<div *ngIf="pageLoaded">
  <div class="row selectorRow">
    <div class="col-lg-3 centerAlign" *ngIf="merchant !== null">
      <nb-card
        class="Selector clickable"
        [ngClass]="{ selected: merchant.name === selectedItem && selectedMerchant }"
        (click)="onChange(merchant.name, true)">
        <img [src]="merchant.colorLogoURL" alt="" />
        <p>{{ merchant.name }}</p>
        <p class="subsubtitle">Merchant</p>
      </nb-card>
    </div>
    <div class="col-lg-3 centerAlign" *ngFor="let organization of possibleOrganizations">
      <nb-card
        class="Selector clickable"
        [ngClass]="{
          selected: organization.organizationName === selectedItem && !selectedMerchant
        }"
        (click)="onChange(organization.organizationName, false)">
        <img [src]="organization.logoURL" alt="" />
        <p>{{ organization.organizationName }}</p>
        <p class="subsubtitle">Organization</p>
      </nb-card>
    </div>
  </div>
  <div class="row element-button">
    <!-- <button *ngIf="!changedView" nbButton hero status="primary" shape="round" class="element-button" (click)="changeView()">Change View</button> -->
    <button
      *ngIf="!changedView && !fromLogin"
      nbButton
      outline
      status="danger"
      shape="round"
      class="w-auto"
      (click)="cancelChange()">
      Cancel
    </button>
    <div *ngIf="changedView" class="loader"></div>
  </div>
</div>
<div class="center" *ngIf="!pageLoaded">
  <div class="loader"></div>
</div>
