import { Currency } from '../organization/organizations/Currency.model';

export class Merchant2 {
  merchantID: number;
  name: string;
  colorLogoURL: string;
  monochromeLogoURL: string;
  imageURL: string;
  color: string;
  darkUI: boolean;
  phoneNumber: string;
  description: string;
  categories: string;
  currency: Currency;
  taxID: string;

  constructor(
    merchantID: number,
    name: string,
    colorLogoURL: string,
    monochromeLogoURL: string,
    imageURL: string,
    color: string,
    darkUI: boolean,
    phoneNumber: string,
    description: string,
    currency: Currency,
    taxID: string
  ) {
    this.merchantID = merchantID;
    this.name = name;
    this.colorLogoURL = this.checkURL(colorLogoURL);
    this.monochromeLogoURL = this.checkURL(monochromeLogoURL);
    this.imageURL = this.checkURL(imageURL);
    this.color = color;
    this.darkUI = darkUI;
    this.phoneNumber = phoneNumber;
    this.description = description;
    this.currency = currency;
    this.taxID = taxID;
  }

  checkURL(url: string) {
    if (url === null || url.startsWith('https') || url.startsWith('http')) {
      return url;
    } else {
      return 'https://' + url;
    }
  }
}
