export class Cashier {
  cashierID: number;
  name: string;
  username: string;
  isActive: boolean;
  endTime: string;
  startTime: string;
  status: string;
  canLoadUp: boolean;
  canRedeem: boolean;
  isBlind: boolean;
  capabilities: string;
  allowManualEntry: boolean;
  canRefund: boolean;
  allowSetPrice: boolean;

  constructor(
    cashierID: number,
    cashierName: string,
    username: string,
    isActive: boolean,
    canLoadUp: boolean,
    canRedeem: boolean,
    startTime: string,
    endTime: string,
    isBlind: boolean,
    allowManualEntry: boolean,
    canRefund: boolean,
    allowSetPrice: boolean,
  ) {
    this.cashierID = cashierID;
    this.name = cashierName;
    this.username = username;
    this.isActive = isActive;
    this.allowManualEntry = allowManualEntry;
    this.canLoadUp = canLoadUp;
    this.canRedeem = canRedeem;
    this.isBlind = isBlind;
    this.canRefund = canRefund;
    this.startTime = startTime;
    this.endTime = endTime;
    this.allowSetPrice = allowSetPrice;
    this.capabilities = this.getCapabilities();
    this.status = this.getStatus();
  }

  getCapabilities() {
    let result = '';

    switch (true) {
      case this.canRefund && this.canLoadUp && this.canRedeem:
        result = 'redeem / load up / refund';
        break;
      case this.canRefund && this.canLoadUp:
        result = 'load up / refund';
        break;
      case this.canRefund && this.canRedeem:
        result = 'redeem / refund';
        break;
      case this.canRefund:
        result = 'refund only';
        break;
      case this.canLoadUp && this.canRedeem:
        result = 'redeem / load up';
        break;
      case this.canLoadUp:
        result = 'load up only';
        break;
      case this.canRedeem:
        result = 'redeem only';
        break;
      default:
        result = 'none';
        break;
    }

    return result;
  }

  getStatus() {
    if (this.isActive) {
      return 'blocked';
    }
    if (this.endTime === null && this.startTime !== null) {
      return 'online';
    }
    return 'offline';
  }
}
