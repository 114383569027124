import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RouteService } from '../../../../route.service';
import { ToastService } from '../../../../toast.service';
import { environment } from '../../../../../environments/environment';
import { Transaction } from './transactions.model';
import * as moment from 'moment';

@Injectable()
export class CashlessTransactionsService {
  selectedOrganization: number;
  baseUrl: string = environment.baseUrl;
  constructor(
    private httpClient: HttpClient,
    private routeService: RouteService,
    private toastService: ToastService
  ) { }

  getCashlessTransactions(organizationID: number, dateRange, merchantID = null) {
    return new Promise((resolve, reject) => {
      let dateFrom = null,
        dateTo = null;
      if (dateRange.startDate && dateRange.endDate) {
        dateFrom = moment(dateRange.startDate).format('YYYY-MM-DD HH:mm:ss');
        dateTo = moment(dateRange.endDate).format('YYYY-MM-DD HH:mm:ss');
      }
      const url: string =
        this.baseUrl +
        `/TransactionsWithMerchant/Admin/?organizationID=${organizationID}${dateFrom ? `&fromDate=${dateFrom}` : ''}${ dateTo ? `&toDate=${dateTo}`: ''}${merchantID ? `&merchantID=${merchantID}` : ''}`;
      const response = this.httpClient.get(url);
      response.subscribe(
        (transactionData: any) => {
          const transactions: Transaction[] = [];
          for (const transaction of transactionData.transactions) {
            console.log(transaction);

            const tempTransaction: Transaction = new Transaction(
              transaction.childID,
              transaction.childFullName ? transaction.childFullName : '',
              `${transaction.parentFirstName} ${transaction.parentLastName}`,
              transaction.phoneNumber,
              transaction.amount,
              transaction.externalStudentID ? transaction.externalStudentID : '',
              transaction.transactionReference,
              moment(transaction.timestamp).format(`MMM DD 'YY hh:mm:ss A`),
              transaction.transactionType,
              transaction.name,
              transaction.branchName,
              transaction.cashierName,
              transaction.currencyCode,
              transaction['Payment Method'],
              transaction.grade,
              transaction.division,
              transaction.className,
              transaction.stage,
            );
            transactions.push(tempTransaction);
          }
          resolve({ transactions, balances: transactionData.balances });
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }
}
