import * as moment from 'moment';

export class AttendanceItem {
  accessID: number;
  adminID: number;
  division: string;
  className: string;
  grade: string;
  stage: string;
  childID: number;
  childName: string;
  deviceName: string;
  externalStudentID: string;
  timestamp: string;
  organizationID: number;
  userID: number;
  adminName?: string;

  constructor(
    accessID: number,
    adminID: number,
    division: string,
    grade: string,
    stage: string,
    className: string,
    childID: number,
    childName: string,
    deviceName: string,
    externalStudentID: string,
    timestamp: string,
    organizationID: number,
    userID: number,
    adminName?: string
  ) {
    this.accessID = accessID;
    this.adminID = adminID;
    this.division = division;
    this.childID = childID;
    this.childName = childName;
    this.deviceName = deviceName;
    this.externalStudentID = externalStudentID;
    this.timestamp = moment(timestamp).format('YYYY-MM-DD, HH:mm');
    this.organizationID = organizationID;
    this.userID = userID;
    this.className = className;
    this.grade = grade;
    this.stage = stage;
    this.adminName = adminName;
  }
}
