import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouteService } from '../../route.service';
import { ToastService } from '../../toast.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class RestrictionsService {
  baseUrl = environment.baseUrl;
  grade: any;
  restrictions: any;
  orgID: number;
  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private routeService: RouteService
  ) {}

  addProductRestriction(products: any, grade: number, orgID: number): Promise<any> {
    console.log(this.grade);
    return new Promise((resolve, reject) => {
      const url = this.baseUrl + '/family-account/product-restriction/Admin';
      this.http.post(url, { products, organizationID: orgID, grade }).subscribe(
        (res) => {
          resolve(res);
          this.toastService.show('success', 'Success', 'Product Restriction Added');
        },
        (error) => {
          this.routeService.checkErr(error).then((spareError: any) => {
            reject(spareError);
            this.toastService.show('danger', 'Error', spareError.err);
          });
        }
      );
    });
  }

  uppdateProductRestriction(productRestrictionID, purchaseLimit, limitDuration, limitType, orgID): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = this.baseUrl + '/family-account/product-restriction/Admin';
      this.http.put(url, { productRestrictionID, purchaseLimit, limitDuration, limitType, organizationID: orgID }).subscribe(
        (res) => {
          resolve(res);
          this.toastService.show('success', 'Success', 'Product Restriction Rules Updated');
        },
        (error) => {
          this.routeService.checkErr(error).then((spareError: any) => {
            reject(spareError);
            this.toastService.show('danger', 'Error', spareError.err);
          });
        }
      );
    });
  }

  getAllProducts(orgID: number) {
    return new Promise((resolve, reject) => {
      const url = `${this.baseUrl}/Products/Admin/Organization/All/?organizationID=${orgID}`;
      this.http.get(url).subscribe(
        (res: any) => {
          resolve(res);
        },
        (error) => {
          this.routeService.checkErr(error).then((error: any) => {
            reject(error);
            this.toastService.show('danger', 'Error', error.err);
          });
        }
      );
    });
  }

  getAllMenuItems(orgID: number) {
    return new Promise((resolve, reject) => {
      const url = `${this.baseUrl}/MenuItem/Admin/allBranches/?organizationID=${orgID}`;
      this.http.get(url).subscribe(
        (res: any) => {
          resolve(res);
        },
        (error) => {
          this.routeService.checkErr(error).then((error: any) => {
            reject(error);
            this.toastService.show('danger', 'Error', error.err);
          });
        }
      );
    });
  }

  getAllProductRestrictions(orgID: number): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${this.baseUrl}/family-account/product-restriction/Admin/?organizationID=${orgID}`;
      this.http.get(url).subscribe(
        (res: any) => {
          resolve(res);
        },
        (error) => {
          this.routeService.checkErr(error).then((spareError: any) => {
            reject(spareError);
            this.toastService.show('danger', 'Error', spareError.err);
          });
        }
      );
    });
  }

  deleteRestriction(productRestrictionIDs: any, orgID): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = this.baseUrl + '/family-account/product-restriction/Admin';
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body: {
          organizationID: orgID,
          productRestrictionIDs,
        },
      };
      this.http.delete(url, options).subscribe(
        (res) => {
          resolve(res);
          this.toastService.show('success', 'Success', 'Product Restriction Removed');
        },
        (error) => {
          this.routeService.checkErr(error).then((spareError: any) => {
            reject(spareError);
            this.toastService.show('danger', 'Error', spareError.err);
          });
        }
      );
    });
  }
}
