import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'spare-logout',
  template: '',
})
export class LogoutComponent implements OnInit {
  constructor(private router: Router, private authService: AuthService) {}

  // Calls on the authservice to remove all the access tokens
  // Then navigates the user to the login page
  ngOnInit() {
    this.authService.removeTokens();
    this.router.navigate(['auth', 'login']);
  }
}
