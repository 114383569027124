import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Gate } from './gate.model';
import { environment } from '../../../environments/environment';
import { lastValueFrom, map } from 'rxjs';

@Injectable()
export class GatesService {
  constructor(private httpClient: HttpClient) {}

  baseUrl: string = environment.baseUrl;
  orgId = localStorage.getItem('spareSelectedOrganization');

  getGates(): Promise<Gate[]> {
    const url = this.baseUrl + `/organization/gates/all`;
    const organizationID = +localStorage.getItem('spareSelectedOrganization');

    const source$ = this.httpClient.get<Gate[]>(url, {
      params: { organizationID },
    }).pipe(
      map(gates => gates.map(gate => ({ ...gate, guardianRequired: !!gate.guardianRequired })))
    );

    return lastValueFrom(source$);
  }

  addGate(gateName: string, guardianRequired: boolean, organizationAdminIDs: number[]) {
    const url = this.baseUrl + '/organization/gates';
    const organizationID = +localStorage.getItem('spareSelectedOrganization');

    const source$ = this.httpClient.post<any>(url, {
      organizationID,
      gateName,
      guardianRequired,
      organizationAdminIDs,
    });

    return lastValueFrom(source$);
  }

  updateGate(
    gateID: number,
    gateName: string,
    guardianRequired: boolean,
    organizationAdminIDs: number[]
  ) {
    const url = this.baseUrl + `/organization/gates/${gateID}`;
    const organizationID = +localStorage.getItem('spareSelectedOrganization');

    const source$ = this.httpClient.put<any>(url, {
      organizationID,
      gateName,
      guardianRequired,
      organizationAdminIDs,
    });

    return lastValueFrom(source$);
  }

  deleteGate(gateID: number) {
    const url = this.baseUrl + `/organization/gates/${gateID}`;
    const organizationID = +localStorage.getItem('spareSelectedOrganization');

    const source$ = this.httpClient.delete<any>(url, {
      params: { organizationID },
    });

    return lastValueFrom(source$);
  }
}
