import {
  HttpClient,
  HttpEventType,
  HttpParams,
  HttpRequest,
  HttpResponse,
  HttpUploadProgressEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable, filter, map } from 'rxjs';

type UploadResponse = { success: boolean; url: { original: string; thumbnail: string } };
type UploadResult = { success?: boolean; percentage: number; url?: string; thumbnail?: string };

@Injectable({
  providedIn: 'root',
})
export class UploadFilesService {
  private uploadUrl = environment.uploadUrl;

  constructor(private http: HttpClient) {}

  upload(
    base64: string,
    folderName: string,
    fileName: string,
    keepFormat = false
  ): Observable<UploadResult> {
    if (!base64) return;

    const blob = this.base64toBlob(base64);
    const file = new File([blob], '', { type: 'image/webp' });
    return this.uploadFile(file, folderName, fileName, keepFormat);
  }

  uploadFile(
    file: File,
    folderName: string,
    fileName: string,
    keepFormat = false
  ): Observable<UploadResult> {
    if (!file) return;

    fileName = folderName + '|' + fileName;
    const form = new FormData();
    form.append('image', file, fileName);

    const params = new HttpParams().set('keep', keepFormat.toString());
    const req = new HttpRequest('POST', this.uploadUrl, form, { reportProgress: true, params });

    return this.http.request(req).pipe(
      filter(
        (event) =>
          event.type === HttpEventType.UploadProgress || event.type === HttpEventType.Response
      ),
      map((event: HttpUploadProgressEvent | HttpResponse<UploadResponse>) => {
        if (event.type === HttpEventType.UploadProgress) {
          return { percentage: Math.round((100 * event.loaded) / event.total) };
        } else if (event.type === HttpEventType.Response) {
          return {
            success: event.body.success,
            percentage: 100,
            url: event.body.url.original,
            thumbnail: event.body.url.thumbnail,
          };
        }
      })
    );
  }

  private base64toBlob(base64Buffer: string) {
    const sliceSize = 1024;
    const [contentType, data] = base64Buffer.split(';base64,');
    const byteCharacters = atob(data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }

      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }

    return new Blob(byteArrays, { type: contentType.substring(5) });
  }
}
