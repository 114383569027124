import Bugsnag from '@bugsnag/js';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

const { production } = environment;

type GenericError = {
  err: string;
  code?: number;
};

@Injectable()
export class RouteService {
  checkErr(err): Promise<GenericError> {
    if (!production)
      Bugsnag.notify = () => {
        console.log('Bugsnag is disabled in non production environment');
      };
    console.error('log', err);
    return new Promise((resolve, _reject) => {
      if (err.status && err.status === 400) {
        if (err.error.code === 111) {
          resolve({ err: err.error?.err });
        } else if (err.error.name === 'ZodError') {
          resolve({ err: err.error.issues[0].message });
        } else {
          resolve({ err: err.error?.validation?.body?.message });
        }
      } else if (err.status && err.status === 401) {
        if (typeof err.error === 'object') {
          if (err.error.code === 100) {
            console.error('sql error');
            resolve({ err: err.error.err.sqlMessage, code: 100 });
          } else {
            if (err.error.err) {
              console.error('another error, not sql');
              resolve({ err: err.error.err, code: err.error.code });
            } else if (err.error.error) {
              console.error('another error, not sql');
              resolve({ err: err.error.error, code: err.error.code });
            }
          }
        } else {
          Bugsnag.notify(err);
          resolve({ err: 'Unauthorized', code: 101 });
        }
      } else if (err.status && err.status === 404) {
        Bugsnag.notify(err);
        resolve({ err: 'Resource not found', code: 404 });
      } else if (err.status && err.status === 422) {
        console.log('type of error', typeof err.error);
        if (typeof err.error === 'object') {
          console.log('err.error', err.error);
          // if (err.error.code === 100) {
          //     console.error('sql error');
          //     resolve({ err: err.error.err.sqlMessage, code: 100 });
          // } else {
          if (typeof err.error.error === 'object' && err.error.error.details) {
            resolve({ err: err.error.error.details[0].message });
          } else if (err.error.err) {
            console.log('returning in the correct place');
            console.error('another error, not sql');
            resolve({ err: err.error.err, code: err.error.code });
          } else if (err.error.error) {
            console.error('another error, not sql');
            resolve({ err: err.error.error, code: err.error.code });
          } else if (err.error.message) {
            resolve({ err: err.error.message });
          }
          // }
        } else {
          this.tryParseJSON(err).then(
            (parsedErr: GenericError) => {
              resolve(parsedErr);
            },
            (err: any) => {
              Bugsnag.notify(err);
              resolve({ err: 'An unexpected error occurred', code: 422 });
            }
          );
        }
      } else if (err.status && err.status === 502) {
        Bugsnag.notify(err);
        resolve({ err: 'The server was unable to respond', code: 502 });
      } else if (typeof err.error === 'string') {
        console.error('error string');
        Bugsnag.notify(err);
        resolve({ err: err.error, code: err.status });
      } else {
        this.tryParseJSON(err).then(
          (parsedErr: GenericError) => {
            resolve(parsedErr);
          },
          (err: any) => {
            Bugsnag.notify(err);
            resolve({ err: 'An unexpected error occurred', code: 422 });
            console.error(err);
          }
        );
      }
    });
  }
  tryParseJSON(err: any): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        const jsonError = err.json();
        if (jsonError && typeof jsonError === 'object') {
          resolve(jsonError);
        } else {
          reject(false);
        }
      } catch (e) {
        reject(false);
      }
    });
  }
}
