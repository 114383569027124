import * as moment from 'moment';

export class Guardian {
  approvedGuardianID: number;
  guardianName: string;
  guardianArabicName: string;
  guardianNationalID: string;
  childID: number;
  addedOn: moment.Moment;
  deletedOn?: moment.Moment;
  peripheralSerial: string;
  childName: string;
  externalStudentID: string;

  constructor(raw: any) {
    Object.assign(this, raw);

    this.addedOn = moment(raw.addedOn);
    if (raw.deletedOn) this.deletedOn = moment(raw.deletedOn);
  }
}
