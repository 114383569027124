export class Store {
  organizationBranchEntryID: number;
  organizationID: number;
  branchID: number;
  area: string;
  branchName: string;
  address: string;
  merchantName: string;
  merchantColorLogoURL: string;
  merchantColorCode: string;
  hooveredOn: boolean;
  merchantID: number;

  constructor(
    organizationBranchEntryID: number,
    organizationID: number,
    branchID: number,
    area: string,
    branchName: string,
    address: string,
    merchantName: string,
    merchantColorLogoURL: string,
    merchantColorCode: string,
    merchantID: number
  ) {
    this.organizationBranchEntryID = organizationBranchEntryID;
    this.organizationID = organizationID;
    this.branchID = branchID;
    this.area = area;
    this.branchName = branchName;
    this.address = address;
    this.merchantName = merchantName;
    this.merchantColorLogoURL = merchantColorLogoURL;
    this.merchantColorCode = merchantColorCode;
    this.merchantID = merchantID;
    if (this.merchantColorLogoURL !== null && !this.merchantColorLogoURL.startsWith('http')) {
      this.merchantColorLogoURL = 'https://' + merchantColorLogoURL;
    }
    this.hooveredOn = false;
  }
}
