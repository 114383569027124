import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'spare-forgor-password',
  templateUrl: './forgotPassword.component.html',
  styleUrls: ['./forgotPassword.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  username: string;
  pageNumber = 0;
  code: string;
  newPassword: string;
  confirmPassword: string;
  loading: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.pageNumber = 0;
    this.changeDetector.detectChanges();
    document.getElementById('usernameInputField').focus();
  }

  // Not yet complete
  resetPassword() {
    if (!this.loading && this.valid()) {
      this.loading = true;
      this.authService.resetPassword(this.username).then(
        () => {
          this.pageNumber = 1;
          this.loading = false;
          this.changeDetector.detectChanges();
          document.getElementById('codeInputField').focus();
        },
        () => {
          this.loading = false;
          this.changeDetector.detectChanges();
        }
      );
    }
  }

  submitCode() {
    if (!this.loading && this.validCode()) {
      this.loading = true;
      this.authService.verifyResetCode(this.code).then(
        () => {
          this.pageNumber = 2;
          this.loading = false;
          this.changeDetector.detectChanges();
          document.getElementById('passwordInputField').focus();
        },
        () => {
          this.loading = false;
          this.changeDetector.detectChanges();
        }
      );
    }
  }

  newPasswordSubmit() {
    if (!this.loading && this.validPassword()) {
      this.loading = true;
      this.authService.setResetPassword(this.newPassword).then(
        () => {
          this.loading = false;
          if (
            (+localStorage.getItem('spareAdminRole') !== 1 &&
              localStorage.getItem('spareRoles') !== '' &&
              localStorage.getItem('spareMerchantID')) ||
            +localStorage.getItem('spareRoles').split(',') > 2
          ) {
            this.authService.fromLogin = true;
            this.router.navigate(['auth', 'change-view']);
          } else {
            this.router.navigate(['']);
          }
        },
        () => {
          this.loading = false;
          this.changeDetector.detectChanges();
        }
      );
    }
  }

  validCode() {
    return this.code !== null && this.code.length === 6;
  }

  valid() {
    return this.username !== null && this.username !== '';
  }

  validPassword() {
    return (
      this.newPassword !== null &&
      this.newPassword.length > 7 &&
      this.newPassword === this.confirmPassword
    );
  }

  returnBack() {
    if (!this.loading) {
      this.router.navigate(['auth', 'login']);
    }
  }
}
