import * as moment from 'moment';
import { PhoneVerifications } from './phone-verifications.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouteService } from '../../route.service';
import { ToastService } from '../../toast.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class PhoneVerificationsService {
  constructor(
    private httpClient: HttpClient,
    private routeService: RouteService,
    private toastService: ToastService
  ) {}

  phoneVerification: PhoneVerifications;

  baseUrl: string = environment.baseUrl;
  // Get all the pins and their data
  getData() {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Digits/Admin/PhoneVerifications/All';
      const phoneVerificationsArray: PhoneVerifications[] = [];
      this.httpClient.get<any>(url).subscribe(
        (phoneVerifications: any) => {
          for (const verification of phoneVerifications) {
            const tempVerification: PhoneVerifications = new PhoneVerifications(
              verification.verificationID,
              verification.phoneNumber,
              this.formatDate(verification.verificationRequestedAt),
              this.formatDate(verification.resendRequestedAt),
              this.formatDate(verification.verifiedAt),
              this.formatDate(verification.successfulLoginAt)
            );
            phoneVerificationsArray.push(tempVerification);
          }
          resolve(phoneVerificationsArray);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  formatDate(date) {
    if (date) {
      return moment(date).format("ddd, MMM DD 'YY hh:mm:ss");
    } else {
      return 'Not Completed';
    }
  }
}
