export class BusMonitors {
  busMonitorID: number;
  name: string;
  username: string;
  phoneNumber: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;

  constructor(
    busMonitorID: number,
    name: string,
    username: string,
    phoneNumber: string,
    email: string,
    firstName?: string,
    lastName?: string,
    password?: string,
    confirmPassword?: string
  ) {
    this.busMonitorID = busMonitorID;
    this.name = name;
    this.username = username;
    this.phoneNumber = phoneNumber;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.password = password;
    this.confirmPassword = confirmPassword;
  }
}
