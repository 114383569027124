import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'round',
  pure: true,
})
export class RoundPipe implements PipeTransform {
  transform(value: string | number, decimals: number): number {
    return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals);
  }
}
