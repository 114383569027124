import { OrganizationBillItem } from './organizationBillItem.model';

export class OrganizationBill {
  billItems: OrganizationBillItem[];
  totalEGPIn: number;
  beingDownloadedSpare: boolean;
  beingDownloadedMerchant: boolean;
  invoiceNumber: string;
  startDate: any;
  endDate: any;
  merchantDue: any;
  spareDue: any;
  statusMerchant: string;
  statusSpare: string;
  totalSales: number;
  totalReversals: number;
  organizationHandlesBilling = false;
  creditCardLoadUps: number;
  totalOverdraft: number;
  totalOverdraftRepaid: number;
  totalOverdraftFees: number;
  currencyCode: string;

  constructor(billItems: OrganizationBillItem[]) {
    this.billItems = billItems;
    this.totalEGPIn = 0;
    this.totalReversals = 0;
    this.totalSales = 0;
    this.creditCardLoadUps = 0;
    this.totalOverdraft = 0;
    this.totalOverdraftRepaid = 0;
    this.totalOverdraftFees = 0;
    if (billItems.length > 0) {
      this.invoiceNumber = '' + billItems[0].invoiceNumber;
      this.startDate = billItems[0].startDate;
      this.endDate = billItems[0].endDate;
      this.merchantDue = billItems[0].merchantToSpareDue;
      this.spareDue = billItems[0].spareToMerchantDue;
      this.organizationHandlesBilling = billItems[0].organizationHandlesBilling;
      this.currencyCode = billItems[0].currencyCode;
    }
    let saprePaidNumber = 0;
    let merchantPaidNumber = 0;
    for (const billItem of billItems) {
      this.totalEGPIn = this.totalEGPIn + billItem.EGPIn;
      this.totalSales = this.totalSales + billItem.totalSalesValue;
      this.totalReversals = this.totalReversals + billItem.totalReversalValue;
      if (billItem.merchantPaidOn !== null) {
        merchantPaidNumber = merchantPaidNumber + 1;
      }
      if (billItem.sparePaidOn !== null) {
        saprePaidNumber = saprePaidNumber + 1;
      }
      if (billItem.branchName === 'Credit Card') {
        this.creditCardLoadUps = billItem.EGPIn;
      }
      if (billItem.totalOverdraft) this.totalOverdraft += billItem.totalOverdraft;
      if (billItem.totalOverdraftRepaid) this.totalOverdraftRepaid += billItem.totalOverdraftRepaid;
      if (billItem.totalOverdraftFees) this.totalOverdraftFees += billItem.totalOverdraftFees;
    }
    if (merchantPaidNumber === 0) {
      this.statusMerchant = 'UNSETTLED';
    } else {
      if (merchantPaidNumber === billItems.length) {
        this.statusMerchant = 'SETTLED';
      } else {
        this.statusMerchant = 'PARTIALLY SETTLED';
      }
    }
    if (saprePaidNumber === 0) {
      this.statusSpare = 'UNSETTLED';
    } else {
      if (saprePaidNumber === billItems.length) {
        this.statusSpare = 'SETTLED';
      } else {
        this.statusSpare = 'PARTIALLY SETTLED';
      }
    }
    this.beingDownloadedSpare = false;
    this.beingDownloadedMerchant = false;
    if (this.invoiceNumber)
      while (this.invoiceNumber.length < 6) this.invoiceNumber = '0' + this.invoiceNumber;
  }
}
