export class FailedTransactions {
  transactionID: number;
  creditAccountID: number;
  debitAccountID: number;
  timestamp;
  reason: string;
  parentName: string;
  childName: string;
  externalStudentID: string;
  amount: number;
  transactionType: string;
  overridePeripheralName: string;

  constructor(
    transactionID: number,
    creditAccountID: number,
    debitAccountID: number,
    timestamp,
    reason: string,
    parentFirstName: string,
    parentLastName: string,
    childName: string,
    amount: number,
    externalStudentID: string,
    overridePeripheralName: string
  ) {
    this.transactionID = transactionID;
    this.creditAccountID = creditAccountID;
    this.debitAccountID = debitAccountID;
    if (creditAccountID === 1 && debitAccountID === 5) this.transactionType = 'Redeem';
    else if (creditAccountID === 8 && debitAccountID === 1) this.transactionType = 'Reversal';
    else if (creditAccountID === 2 && debitAccountID === 1) this.transactionType = 'Load Up';
    this.timestamp = timestamp;
    this.reason = reason;
    this.parentName = parentFirstName ? parentFirstName + ' ' + parentLastName : 'Not Available';
    this.childName = childName ? childName : 'Not Available';
    this.externalStudentID = externalStudentID ? externalStudentID : 'Not Available';
    this.amount = amount;
    this.overridePeripheralName = overridePeripheralName
      ? overridePeripheralName
      : 'Not Overridden';
  }
}
