import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { DashboardService } from '../../../pages/dashboard/dashboard.service';
import { Merchant } from '../../../pages/dashboard/merchant.model';
import { MerchantOrganizationService } from '../../../pages/organization/organizations/organization.service';
import { Organization } from '../../../pages/organization/organizations/organization.model';
import { Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'spare-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  @Input() position = 'normal';
  user: any;
  userMenu: any = [];
  isMobile = false;

  getIsMobile(): boolean {
    const w = document.documentElement.clientWidth;
    const breakpoint = 992;
    if (w < breakpoint) {
      return true;
    } else {
      return false;
    }
  }

  constructor(
    private organizationService: MerchantOrganizationService,
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private dashboardService: DashboardService,
    public changeDetector: ChangeDetectorRef,
    private router: Router
  ) {}

  // At the initialisation of the header component the admin role is checked
  // and the available functionalities is displayed accordingly
  // Only the super admin and the spare support are allowed to change the merchant id
  // All users could change password and logout
  ngOnInit() {
    if (localStorage.getItem('spareView') === 'merchant') {
      this.dashboardService.getMerchant().then((res: Merchant) => {
        this.user = {
          name: localStorage.getItem('spareAdminUsername') + ' (Merchant)',
          picture: res.colorLogoURL,
          title: res.name,
        };
        this.changeDetector.detectChanges();
      });
    } else {
      this.organizationService.getOrganizations(false).then(
        (organizations: Organization[]) => {
          for (const organization of organizations) {
            if (organization.organizationID == +localStorage.getItem('spareSelectedOrganization')) {
              this.user = {
                name: localStorage.getItem('spareAdminUsername') + ' (Organization)',
                picture: organization.logoURL,
                title: organization.organizationName,
              };
              this.changeDetector.detectChanges();
            }
          }
        },
        (err) => console.error(err)
      );
    }
    if (+localStorage.getItem('spareAdminRole') & (1 + 4)) {
      this.userMenu.push({ title: 'Change Merchant', link: '/pages/auth/change-merchant' });
    }
    this.userMenu.push({ title: 'Change Password', link: '/pages/auth/reset-password' });
    if (+localStorage.getItem('spareAdminRole') & (1 + 2 + 8 + 1048576)) {
      this.userMenu.push({ title: 'Manage Admins', link: '/pages/auth/admins' });
    }
    this.userMenu.push({ title: 'Logout', link: '/auth/logout' });

    this.changeDetector.detectChanges();
    this.isMobile = this.getIsMobile();
    window.onresize = () => {
      this.isMobile = this.getIsMobile();
    };

    this.menuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'user-profile-menu'),
        map(({ item: { title } }) => title)
      )
      .subscribe((title) => console.log('Wow', title));
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, 'settings-sidebar');
    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    // this.analyticsService.trackEvent('startSearch');
  }

  canChangeView() {
    return localStorage.getItem('spareRoles') != '';
  }

  onChangeView() {
    this.router.navigate(['auth', 'change-view']);
  }
}
