export class Transaction {
  childID: number;
  childFullName: string;
  username: string;
  userPhoneNumber: string;
  amount: number;
  externalStudentID: string;
  transactionReference: string;
  timestamp: string;
  transactionType: string;
  merchantName: string;
  branchName: string;
  cashierName: string;
  currencyCode: string;
  paymentMethod: string;
  grade: string;
  division: string;
  className: string;
  stage: string;
  constructor(
    childID: number,
    childFullName: string,
    username: string,
    userPhoneNumber: string,
    amount: number,
    externalStudentID: string,
    transactionReference: string,
    timestamp: string,
    transactionType: string,
    merchantName: string,
    branchName: string,
    cashierName: string,
    currencyCode: string,
    paymentMethod: string,
    grade: string,
    division: string,
    className: string,
    stage: string,
  ) {
    this.childID = childID;
    this.childFullName = childFullName;
    this.username = username;
    this.userPhoneNumber = userPhoneNumber;
    this.amount = amount;
    this.externalStudentID = externalStudentID;
    this.transactionReference = transactionReference;
    this.timestamp = timestamp;
    this.merchantName = merchantName;
    this.branchName = branchName;
    this.cashierName = cashierName;
    this.currencyCode = currencyCode;
    this.transactionType = transactionType;
    this.paymentMethod = paymentMethod;
    this.grade = grade;
    this.division = division;
    this.className = className;
    this.stage = stage;
  }
}
