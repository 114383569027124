<form [formGroup]="form" (ngSubmit)="login()" class="AddMargin">
  <div class="row">
    <img src="../../../assets/images/logoSmall.svg" class="element-img" />
  </div>
  <div class="row">
    <h2 class="title">Admin Login</h2>
  </div>
  <div class="row">
    <h4 class="title">Hello! Sign in with your username</h4>
  </div>
  <div class="row">
    <input
      formControlName="username"
      class="element"
      placeholder="Username"
      required
      type="text"
      nbInput
      shape="round"
      fullWidth />
  </div>
  <!-- <div class="rowError">
    <small class="form-text error element" *ngIf="usernameInput.touched">{{ validUserName() }}</small>
  </div> -->
  <div class="row">
    <input
      formControlName="password"
      class="element"
      placeholder="Password"
      required
      type="password"
      nbInput
      shape="round"
      fullWidth />
  </div>
  <!-- <div class="rowError">
    <small class="form-text error element" *ngIf="passwordInput.touched">{{ validPassword() }}</small>
  </div> -->
  <div class="row" *ngIf="!loggedIn">
    <small class="form-text element clickable w-auto" (click)="forgotPassword()"
      >Forgot Password?</small
    >
  </div>
  <div class="row">
    <button
      *ngIf="!loggedIn"
      nbButton
      hero
      status="primary"
      shape="round"
      type="submit"
      class="element-button w-auto"
      [disabled]="!form.valid">
      Login
    </button>
    <div *ngIf="loggedIn" class="loader"></div>
  </div>
</form>
