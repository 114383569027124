import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Area } from './area.model';
import { RouteService } from '../../route.service';

import { ToastService } from '../../toast.service';
import { environment } from '../../../environments/environment';
@Injectable()
export class AreasService {
  areas: Area[] = [];
  selectedArea: Area;
  baseUrl: string = environment.baseUrl;
  // At the start of the service the list of
  // all possible branches and cashiers is loaded for the filter dropdown selection
  constructor(
    private httpClient: HttpClient,
    private routeService: RouteService,
    private toastService: ToastService
  ) {}

  // get data returns 50 transactions
  // from the offset the method also checks whether there are further transactions or not
  // By loading 51 if the loaded transaction size is 51 then further transactions exists
  getData() {
    return new Promise((resolve, reject) => {
      const areasArray: Area[] = [];
      const url: string = this.baseUrl + '/Areas/All';
      this.httpClient.get<any>(url).subscribe(
        (areas: any) => {
          for (const area of areas) {
            const tempArea: Area = new Area(area.areaID, area.areaName);
            areasArray.push(tempArea);
          }
          this.areas = areasArray;
          resolve(this.areas);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  addArea(name: string) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Areas/Add';
      const body = { areaName: name };
      this.httpClient.post(url, body).subscribe(
        () => {
          resolve('success');
          this.toastService.show('success', 'Success', 'Area was added successfully');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  updateArea(name: string) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Areas/Update';
      const body = {
        areaName: name,
        areaID: this.selectedArea.areaID,
      };
      this.httpClient.post(url, body).subscribe(
        () => {
          resolve('success');
          this.toastService.show('success', 'Success', 'Area was updated successfully');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  deleteArea() {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Areas/Delete';
      this.httpClient
        .post(url, {
          areaID: this.selectedArea.areaID,
        })
        .subscribe(
          () => {
            resolve('success');
            this.toastService.show('success', 'Success', 'Area was deleted successfully');
          },
          (error) => {
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }
}
