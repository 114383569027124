export class PaymentRequests {
  paymentRequestID: number;
  adminID: number;
  createdByAdminName: string;
  parentName: string;
  childID: number;
  childName: string;
  userID: number;
  organizationID: number;
  requestReason: string;
  requestAmount: number;
  requestType: string;
  requestedOn: string;
  cancelledOn?: string;
  cancellationReason?: string;
  cancelledByAdminID?: number;
  cancelledByAdminName?: string;
  lastNotified?: string;
  numberOfTimesNotified?: number;
  paidOn?: string;
  transactionReference?: string;
  rejectedOn?: string;
  rejectionReason?: string;
  refundedOn?: string;
  refundReason?: string;
  refundedByAdminID?: number;
  refundedByAdminName?: string;
  refundTransactionReference?: string;
  familyBalance?: number;
  currencyCode?: number;
  currencyLongName?: number;
  branchName?: string;
  externalStudentID: string;

  constructor(
    paymentRequestID: number,
    adminID: number,
    createdByAdminName: string,
    parentName: string,
    childID: number,
    childName: string,
    userID: number,
    organizationID: number,
    requestReason: string,
    requestAmount: number,
    requestType: string,
    requestedOn: string,
    cancelledOn: string,
    cancellationReason: string,
    cancelledByAdminID: number,
    cancelledByAdminName: string,
    lastNotified: string,
    numberOfTimesNotified: number,
    paidOn: string,
    transactionReference: string,
    rejectedOn: string,
    rejectionReason: string,
    refundedOn: string,
    refundReason: string,
    refundedByAdminID: number,
    refundedByAdminName: string,
    refundTransactionReference: string,
    familyBalance: number,
    currencyCode: number,
    currencyLongName: number,
    branchName: string,
    externalStudentID: string
  ) {
    this.paymentRequestID = paymentRequestID;
    this.adminID = adminID;
    this.createdByAdminName = createdByAdminName;
    this.parentName = parentName;
    this.childID = childID;
    this.childName = childName;
    this.userID = userID;
    this.organizationID = organizationID;
    this.requestReason = requestReason;
    this.requestAmount = requestAmount;
    this.requestType = requestType;
    this.requestedOn = requestedOn;
    this.cancelledOn = cancelledOn;
    this.cancellationReason = cancellationReason;
    this.cancelledByAdminID = cancelledByAdminID;
    this.cancelledByAdminName = cancelledByAdminName;
    this.lastNotified = lastNotified;
    this.numberOfTimesNotified = numberOfTimesNotified;
    this.paidOn = paidOn;
    this.transactionReference = transactionReference;
    this.rejectedOn = rejectedOn;
    this.rejectionReason = rejectionReason;
    this.refundedOn = refundedOn;
    this.refundReason = refundReason;
    this.refundedByAdminID = refundedByAdminID;
    this.refundedByAdminName = refundedByAdminName;
    this.refundTransactionReference = refundTransactionReference;
    this.familyBalance = familyBalance;
    this.currencyCode = currencyCode;
    this.currencyLongName = currencyLongName;
    this.branchName = branchName;
    this.externalStudentID = externalStudentID;
  }
}
