import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isToResetPass =
      req.url.endsWith('checkAdminUsernameExists') ||
      req.url.endsWith('PasswordResetCode') ||
      req.url.endsWith('CheckPasswordResetCode') ||
      req.url.endsWith('Reset/UpdateAdminPassword');
    const token = isToResetPass
      ? window.localStorage.getItem('spareEmailToken') || ''
      : window.localStorage.getItem('spareToken') || '';
    // Clone the request to add the new header.
    let authReq;
    const spareView: string = localStorage.getItem('spareView');
    if (spareView === 'merchant' || !spareView) {
      authReq = req.clone({
        setHeaders: {
          Authorization: token,
          'Access-Control-Allow-Origin': '*',
        },
      });
    } else if (spareView === 'organization') {
      const orgID = window.localStorage.getItem('spareSelectedOrganization')
        ? window.localStorage.getItem('spareSelectedOrganization')
        : '';

      authReq = req.clone({
        setHeaders: {
          Authorization: token,
          'x-organization-id': orgID,
          'Access-Control-Allow-Origin': '*',
        },
      });
    }

    // send the newly created request
    return next.handle(authReq).pipe(
      catchError((error, _caught) => {
        console.log('error occurred in interceptor ', error);
        // if (error.status === 401) {
        //     this.toastService.showToast('danger', 'Error', 'You do not have the neccesary permissions');
        // }
        return throwError(error);
      })
    ) as any;
  }
}
