export class Menu {
  menuID: number;
  branchID: number;
  branchName: string;
  stockTracked: boolean;
  showInApp: boolean;
  numberOfProducts: number;
  numberOfSections: number;
  collapsed: boolean; //Collapsed to check if the user collapsing the menu or opening to see details
  restockNeeded: boolean; // is true if any of the items in the menu has stock of 3 or less
  chart: any; // To display the chart for the menu
  chartLoaded: boolean; // To check if the chart is loaded or not
  empty: boolean; // To check if the user has any products or is it empty
  numberOfItems: number;

  constructor(
    menuID: number,
    branchID: number,
    branchName: string,
    stockTracked: boolean,
    showInApp: boolean,
    numberOfProducts: number,
    numberOfSections: number
  ) {
    this.menuID = menuID;
    this.branchID = branchID;
    this.branchName = branchName;
    this.stockTracked = stockTracked;
    this.showInApp = showInApp;
    this.numberOfProducts = numberOfProducts;
    this.numberOfSections = numberOfSections;
    this.collapsed = true;
    this.restockNeeded = false;
    this.chart = [];
    this.chartLoaded = false;
    this.empty = false;
    this.numberOfItems = 0;
  }
}
