export class VerifiedUser {
  verifiedUserID: number;
  userID: number;
  organizationID: number;
  isBlocked: boolean;
  firstName: string;
  lastName: string;
  name: string;
  status: string;
  emailPostFix: string;
  externalPeripheralID: string;
  verification: string;

  constructor(
    verifiedUserID: number,
    userID: number,
    organizationID: number,
    isBlocked: boolean,
    firstName: string,
    lastName: string,
    emailPostFix: string,
    externalPeripheralID: string
  ) {
    this.verifiedUserID = verifiedUserID;
    this.userID = userID;
    this.organizationID = organizationID;
    this.isBlocked = isBlocked;
    this.firstName = firstName;
    this.lastName = lastName;
    this.name = firstName + ' ' + lastName;
    if (isBlocked) {
      this.status = 'BLOCKED';
    } else {
      this.status = 'VERIFIED';
    }
    this.emailPostFix = emailPostFix;
    this.externalPeripheralID = externalPeripheralID;
  }
}
