export class Child {
  childID: number;
  fullName: string;
  externalStudentID: string;
  organizationID: number;
  className: string;
  grade: string;
  fatherPhone: string;
  motherPhone: string;
  userID: number;
  childPhone: string;
  fatherName: string;
  motherName: string;
  division: string;
  stage: string;
  externalPeripheralID: string;
  connectionID: number;
  isActive: number;
  isPaired: number;
  attendanceTime: string;
  deviceName: string;
  attendanceStatus: string;
  balance: number;
  amountWithoutPin: number;
  requirePinUnderX: number;
  spendingLimitDaily: number;
  spendingLimitWeekly: number;
  spendingLimitMonthly: number;
  userFirstName: string;
  userLastName: string;
  connectedSince: string;
  currencyCode: string;
  currencyLongName: string;
  registrationDate: string;
  deactivationUUID: string;
  deactivatedOn?: string;
  deactivatedBy?: string;
  deactivationReason?: string;
  externalPeripheralIDs?: string[];
  guardianRequired: boolean;
  lastCheckInTimestampToday: string;
  lastGateCheckedInAtToday: string;
  parentUserName: string;

  constructor(
    childID: number,
    fullName: string,
    externalStudentID: string,
    organizationID: number,
    className: string,
    grade: string,
    fatherPhone: string,
    motherPhone: string,
    userID: number,
    childPhone: string,
    fatherName: string,
    motherName: string,
    division: string,
    stage: string,
    externalPeripheralID: string,
    connectionID: number,
    isActive: number,
    isPaired: number,
    balance: number,
    amountWithoutPin: number,
    requirePinUnderX: number,
    spendingLimitWeekly: number,
    spendingLimitDaily: number,
    spendingLimitMonthly: number,
    userFirstName: string,
    userLastName: string,
    connectedSince: string,
    currencyCode: string,
    currencyLongName: string,
    registrationDate: string,
    deactivationUUID: string,
    lastCheckInTimestampToday: string,
    lastGateCheckedInAtToday: string,
    guardianRequired: boolean,
    deactivatedOn?: string,
    deactivationReason?: string,
    deactivatedBy?: string,
    externalPeripheralIDs?: string[]
  ) {
    this.childID = childID;
    this.fullName = fullName;
    this.externalStudentID = externalStudentID;
    this.organizationID = organizationID;
    this.className = className ? className : '-';
    this.grade = grade ? grade : '-';
    this.fatherPhone = fatherPhone ? fatherPhone : '-';
    this.motherPhone = motherPhone ? motherPhone : '-';
    this.userID = userID;
    this.childPhone = childPhone ? childPhone : '-';
    this.fatherName = fatherName ? fatherName : '-';
    this.motherName = motherName ? motherName : '-';
    this.division = division ? division : '-';
    this.stage = stage ? stage : '-';
    this.externalPeripheralID = externalPeripheralID ? externalPeripheralID : '-';
    this.connectionID = connectionID;
    this.isActive = isActive;
    this.isPaired = isPaired;
    this.balance = balance;
    this.amountWithoutPin = amountWithoutPin;
    this.requirePinUnderX = requirePinUnderX;
    this.spendingLimitDaily = spendingLimitDaily;
    this.spendingLimitWeekly = spendingLimitWeekly;
    this.spendingLimitMonthly = spendingLimitMonthly;
    this.userFirstName = userFirstName ? userFirstName : '-';
    this.userLastName = userLastName ? userLastName : '-';
    this.parentUserName = userFirstName + ' ' + userLastName;
    this.connectedSince = connectedSince ? connectedSince : '-';
    this.currencyCode = currencyCode;
    this.currencyLongName = currencyLongName;
    this.registrationDate = registrationDate ? registrationDate : '-';
    this.deactivationUUID = deactivationUUID;
    this.deactivatedBy = deactivatedBy;
    this.deactivatedOn = deactivatedOn;
    this.deactivationReason = deactivationReason;
    this.externalPeripheralIDs = externalPeripheralIDs;
    this.lastCheckInTimestampToday = lastCheckInTimestampToday;
    this.lastGateCheckedInAtToday = lastGateCheckedInAtToday;
    this.guardianRequired = !!guardianRequired;
  }

  processAttendance() {
    if (this.userID) {
      if (this.lastCheckInTimestampToday && this.lastGateCheckedInAtToday) {
        this.attendanceStatus = this.lastGateCheckedInAtToday;
      } else if (
        this.lastCheckInTimestampToday === null ||
        this.lastCheckInTimestampToday === 'N/A'
      ) {
        this.lastCheckInTimestampToday = '-';
        this.lastGateCheckedInAtToday = '-';
        this.attendanceStatus = '-';
      }
    } else {
      this.lastCheckInTimestampToday = '-';
      this.lastGateCheckedInAtToday = '-';
      this.attendanceStatus = '-';
    }
  }
}
