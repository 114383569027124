import * as moment from 'moment';

export class PickupActivity {
  pickupRequestID: number;
  requestedOn: moment.Moment;
  pickUpDate: string;
  pickUpTime: string;
  completedOn?: moment.Moment;
  childID: number;
  externalStudentID: string;
  fullName: string;
  grade: string;
  className: string;
  approvedGuardianID?: number;
  guardianName?: string;
  gateID: number;
  gateName: string;
  adminID?: number;
  name?: number;
  username?: number;

  constructor(raw: any) {
    Object.assign(this, raw);

    this.requestedOn = moment(raw.requestedOn);
    this.pickUpDate = this.requestedOn.format('YYYY-MM-DD');
    this.pickUpTime = this.requestedOn.format('h:mm A');
    this.completedOn = raw.completedOn ? moment(raw.completedOn) : null;
  }
}
