import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class SalesGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (+localStorage.getItem('spareAdminRole') & (1 + 2 + 256 + 262144)) {
      return true;
    } else {
      return false;
    }
  }
}
