import { Cashier } from './cashier.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouteService } from '../../../route.service';
import { ToastService } from '../../../toast.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class CashiersService {
  constructor(
    private httpClient: HttpClient,
    private routeService: RouteService,
    private toastService: ToastService
  ) {}

  cashiersIdMap = new Map();
  cashiers: Cashier[] = [];
  cashiersUsernames: string[] = [];
  selectedCashier: Cashier;
  selectedIndex: number;
  selectedCashierId: number;
  selectedCashierName: string;

  baseUrl: string = environment.baseUrl;
  // Get all the cashiers and thier data
  getData() {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Cashier/Admin/Cashiers';
      const cashiersArray: Cashier[] = [];
      const cashiersUsernamesArray: string[] = [];
      this.httpClient.get<any>(url).subscribe(
        (cashiers: any) => {
          for (const cashier of cashiers) {
            const tempCashier: Cashier = new Cashier(
              cashier.cashierID,
              cashier.name,
              cashier.username,
              cashier.isActive !== 1,
              cashier.canLoadUp === 1,
              cashier.canRedeem === 1,
              cashier.startTime,
              cashier.endTime,
              cashier.isBlind,
              cashier.allowManualEntry,
              cashier.canRefund,
              cashier.allowSetPrice
            );
            cashiersArray.push(tempCashier);
            cashiersUsernamesArray.push(cashier.username);
            this.cashiersIdMap.set(cashier.username, cashier.cashierID);
          }
          this.cashiers = cashiersArray;
          this.cashiersUsernames = cashiersUsernamesArray;
          resolve(this.cashiers);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  // Updates the selected cashiers
  updateSelected(selectedCashier: string) {
    this.selectedIndex = this.cashiersUsernames.indexOf(selectedCashier);
    this.selectedCashier = this.cashiers[this.selectedIndex];
    this.selectedCashierId = this.cashiersIdMap.get(selectedCashier);
    this.selectedCashierName = selectedCashier;
  }

  // Update the cashier by calling the API
  updateCashier(
    name: string,
    isActive: boolean,
    canLoadup: boolean,
    canRedeem: boolean,
    isBlind: boolean,
    allowManualEntry: boolean,
    canRefund: boolean,
    allowSetPrice: boolean,
  ) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Cashier/Admin/UpdateCashier';
      const response = this.httpClient.post(url, {
        name: name,
        isActive: isActive ? 0 : 1,
        canLoadUp: canLoadup ? 1 : 0,
        canRedeem: canRedeem ? 1 : 0,
        isBlind: isBlind ? 1 : 0,
        allowManualEntry: allowManualEntry ? 1 : 0,
        cashierID: this.selectedCashierId,
        canRefund: canRefund ? 1 : 0,
        allowSetPrice: allowSetPrice ? 1 : 0,
      });
      response.subscribe(
        () => {
          resolve('success');
          this.toastService.show('success', 'Success', 'Cashier updated');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  // Add a new cashier
  addCashier(
    name: string,
    username: string,
    isActive: boolean,
    canLoadup: boolean,
    canRedeem: boolean,
    password: string,
    isBlind: boolean,
    allowManualEntry: boolean,
    canRefund: boolean,
    allowSetPrice: boolean,
  ) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Cashier/Admin/Add';
      const response = this.httpClient.post(url, {
        username: username,
        password: password,
        name: name,
        isActive: isActive ? 0 : 1,
        canLoadUp: canLoadup ? 1 : 0,
        canRedeem: canRedeem ? 1 : 0,
        isBlind: isBlind ? 1 : 0,
        allowManualEntry: allowManualEntry ? 1 : 0,
        canRefund: canRefund ? 1 : 0,
        allowSetPrice: allowSetPrice ? 1 : 0,
      });
      response.subscribe(
        () => {
          resolve('success');
          this.toastService.show('success', 'Success', 'Cashier added successfully');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  // Update the cashier password
  updateCashierPassword(username: string, newPassword: string) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Cashier/Admin/ChangePassword';
      const response = this.httpClient.post(url, {
        cashierID: this.cashiersIdMap.get(username),
        newPassword: newPassword,
      });
      response.subscribe(
        () => {
          resolve('success');
          this.toastService.show('success', 'Success', 'Password Updated');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }
}
