import { Tender } from '../../tenders/tender.model';

export class Branch {
  branchName: string;
  area: string;
  address: string;
  externalBranchUUID: string;
  gpsCoordinates: string;
  canLoadUp: boolean;
  canRedeem: boolean;
  capabilities: string;
  organizationName: string;
  branchID: number;
  isOnlineBranch: boolean;
  autoDeliversProducts: boolean;
  tenders: Tender[];

  constructor(
    branchName: string,
    area: string,
    address: string,
    gpsCoordinates: string,
    canLoadUp: boolean,
    canRedeem: boolean,
    organizationName: string,
    isOnlineBranch: boolean,
    autoDeliversProducts: boolean,
    tendersJSON: string,
    externalBranchUUID: string,
    branchID?: number
  ) {
    this.branchName = branchName;
    this.area = area;
    this.address = address;
    this.gpsCoordinates = gpsCoordinates;
    this.canLoadUp = canLoadUp;
    this.canRedeem = canRedeem;
    this.organizationName = organizationName;
    this.isOnlineBranch = isOnlineBranch;
    this.autoDeliversProducts = autoDeliversProducts;
    this.tenders = JSON.parse(tendersJSON) || [];
    this.externalBranchUUID = externalBranchUUID;
    if (canRedeem) {
      if (canLoadUp) {
        this.capabilities = 'redeem / load up';
      } else {
        this.capabilities = 'redeem only';
      }
    } else {
      if (canLoadUp) {
        this.capabilities = 'load up only';
      } else {
        this.capabilities = 'Invalid';
      }
    }
    if (branchID) {
      this.branchID = branchID;
    }
  }
}
