export class Product {
  productID: number;
  externalSKU: string;
  merchantID: number;
  categoryID: number;
  name: string;
  imageURL: string;
  description: string;
  price: number; // up to 2 decimal places
  finalPrice: number;
  finalPriceString: string;
  status: boolean; // boolean true or false
  validityInDays: number;
  VAT: number;
  otherTax: number;
  inclusiveVAT: boolean;
  allergenIDs: string; //e.g. 1,2,4,7,3
  sectionID: number;
  servingSize: string;
  collapsed: boolean; // To display or hide full details i nthe products page
  currencyCode: string;
  currencyPrecision: number;
  merchantName?: string;
  orderInSection: number;
  voucherQuantity?: number;
  voucherID?: number;

  constructor(product: {
    productID: number;
    externalSKU: string;
    merchantID: number;
    categoryID: number;
    name: string;
    imageURL: string;
    description: string;
    price: number;
    status: boolean | number;
    validityInDay: number;
    vat: number;
    otherTax: number;
    inclusiveVAT: boolean;
    allergenIDs: string;
    sectionID: number;
    servingSize: string;
    currencyCode: string;
    currencyPrecision: number;
    orderInSection: number;
    merchantName?: string;
    voucherID?: number;
    voucherQuantity?: number;
    finalPrice?: number;
    finalPriceString?: string;
  }) {
    this.productID = product.productID;
    this.externalSKU = product.externalSKU;
    this.merchantID = product.merchantID;
    this.categoryID = product.categoryID;
    this.name = product.name;
    this.imageURL = this.checkURL(product.imageURL);
    this.description = product.description;
    this.price = product.price;
    this.status = typeof product.status === 'boolean' ? product.status : product.status === 1;
    this.validityInDays = product.validityInDay;
    this.VAT = product.vat;
    this.otherTax = product.otherTax;
    this.inclusiveVAT = product.inclusiveVAT;
    this.allergenIDs = product.allergenIDs;
    this.sectionID = product.sectionID;
    this.servingSize = product.servingSize;
    this.collapsed = true;
    this.merchantName = product.merchantName;
    this.currencyCode = product.currencyCode;
    this.currencyPrecision = product.currencyPrecision;
    this.orderInSection = product.orderInSection;
    this.voucherID = product.voucherID;
    this.voucherQuantity = product.voucherQuantity;
    this.finalPrice = product.finalPrice;
    this.finalPriceString = product.finalPriceString;
  }

  checkURL(url: string) {
    if (url.startsWith('http') || url.startsWith('https')) {
      return url;
    } else {
      return 'https://' + url;
    }
  }
}
