import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RouteService } from '../../route.service';
import { ToastService } from '../../toast.service';
import { environment } from '../../../environments/environment';
import { CountryCode } from './country-codes.model';

@Injectable()
export class CountryCodesService {
  baseUrl: string = environment.baseUrl;
  countryCodes: CountryCode[] = [];
  selectedCountryCode: CountryCode;
  constructor(
    private httpClient: HttpClient,
    private routeService: RouteService,
    private toastService: ToastService
  ) {}

  getData(): Promise<CountryCode[]>{
    return new Promise((resolve, reject) => {
      const codesArray: CountryCode[] = [];
      const url: string = this.baseUrl + '/Digits/Admin/CountryCodes';
      this.httpClient.get<any>(url).subscribe(
        (codes: any) => {
          for (const code of codes) {
            const tempCode: CountryCode = new CountryCode(
              code.countryCodeID,
              code.countryCode,
              code.countryName,
              code.iconURL,
              code.smsProviderName,
              code.resendSMSProviderName
            );
            codesArray.push(tempCode);
          }
          this.countryCodes = codesArray;
          resolve(this.countryCodes);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  addCode(countryCode: string, countryName: string, smsProviderName: string, resendSMSProviderName: string, iconURL: string) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Digits/Admin/CountryCodes/Add';
      const body = {
        countryCode,
        countryName,
        smsProviderName,
        resendSMSProviderName,
        iconURL,
      };
      this.httpClient.post(url, body).subscribe(
        () => {
          resolve('success');
          this.toastService.show('success', 'Success', 'Country Code was added successfully');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  updateCountryCode(countryCode, countryName, smsProviderName, resendSMSProviderName, iconURL, countryCodeID) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Digits/Admin/CountryCodes/Update';
      const body = {
        countryCode,
        countryName,
        smsProviderName,
        resendSMSProviderName,
        iconURL,
        countryCodeID,
      };
      this.httpClient.put(url, body).subscribe(
        () => {
          resolve('success');
          this.toastService.show('success', 'Success', 'Country Code was updated successfully');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  deleteCode() {
    return new Promise((resolve, reject) => {
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body: {
          countryCodeID: this.selectedCountryCode.countryCodeID,
        },
      };
      const url: string = this.baseUrl + '/Digits/Admin/CountryCodes';
      this.httpClient.delete(url, options).subscribe(
        () => {
          resolve('success');
          this.toastService.show('success', 'Success', 'Country Code was deleted successfully');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }
}
