export class CountryCode {
  countryCodeID: number;
  countryCode: string;
  countryName: string;
  iconURL: string;
  smsProviderName: string;
  resendSMSProviderName: string;

  constructor(
    countryCodeID: number,
    countryCode: string,
    countryName: string,
    iconURL: string,
    smsProviderName: string,
    resendSMSProviderName: string
  ) {
    this.countryCodeID = countryCodeID;
    this.countryCode = countryCode;
    this.countryName = countryName;
    this.iconURL = iconURL;
    this.smsProviderName = smsProviderName;
    this.resendSMSProviderName = resendSMSProviderName;
  }
}
