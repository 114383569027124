import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastService } from '../../../../toast.service';

@Injectable()
export class OrganizationBillingGuard implements CanActivate {
  constructor(private router: Router, private toastService: ToastService) {}
  canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const orgRole: number = +localStorage.getItem('spareSelectedOrganizationRole');
    // const showBilling: string = localStorage.getItem('spareShowBilling');
    if (orgRole & (1 + 2 + 4 + 8) || orgRole & 1) {
      return true;
    } else {
      this.router.navigate(['../']).then(() => {
        this.toastService.show(
          'danger',
          "You don't have the permission to view this content",
          '',
          5000
        );
      });
      return false;
    }
  }
}
