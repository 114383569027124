import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Link } from './model/link.model';
import { RouteService } from './../../route.service';
import { ToastService } from './../../toast.service';
import { environment } from '../../../environments/environment';
import { lastValueFrom } from 'rxjs';
import { SpareFilter } from '../../ui/spare-components/filters/filter.model';
import * as moment from 'moment';

@Injectable()
export class ImportantLinksService {
  getLinkFiltersByPostID(postID: number): Promise<SpareFilter[]> {
    const orgID = localStorage.getItem('spareSelectedOrganization');
    const url = `${this.baseUrl}/important-link/filters/Admin/${postID}?organizationID=${orgID}`;
    const source$ = this.httpClient.get<SpareFilter[]>(url);
    return lastValueFrom(source$);
  }
  orgID = localStorage.getItem('spareSelectedOrganization');

  baseUrl: string = environment.baseUrl;
  uploadedPercentage: number;
  constructor(
    private httpClient: HttpClient,
    private toastService: ToastService,
    private routeService: RouteService
  ) { }

  async addImportantLink(postLink: Link) {
    try {
      const url = `${this.baseUrl}/important-link/admin/?organizationID=${postLink.organizationID}`;
      const data = {
        postTitle: postLink.title,
        postDescription: postLink.description,
        linkURL: postLink.linkURL.trim(),
        organizationID: postLink.organizationID,
        imageURL: postLink.imageURL,
        deactivateAt: postLink.deactivateAt,
        filters: postLink.filters,
      };
      const res = this.httpClient.post<any>(url, data);
      return lastValueFrom(res);
    } catch (err) {
      await this.routeService.checkErr(err);
      throw err;
    }
  }

  async updateImportantLink(postLink: Link) {
    try {
      const data = {
        postTitle: postLink.title,
        postDescription: postLink.description,
        linkURL: postLink.linkURL.trim(),
        organizationID: postLink.organizationID,
        imageURL: postLink.imageURL,
        deactivateAt: postLink.deactivateAt,
        filters: postLink.filters,
      };
      const url = `${this.baseUrl}/important-link/admin/${postLink.postID}?organizationID=${postLink.organizationID}`;

      const res = this.httpClient.put(url, data);
      return lastValueFrom(res);
    } catch (err) {
      await this.routeService.checkErr(err);
      throw err;
    }
  }

  deleteImportantLink(linkID: number) {
    return new Promise((resolve, reject) => {
      const url = `${this.baseUrl}/important-link/admin/${linkID}?organizationID=${this.orgID}`;
      this.httpClient.delete(url).subscribe(
        () => {
          resolve('success');
          this.toastService.show('success', 'Success', 'Link Deleted');
        },
        async (err) => {
          const spareError = await this.routeService.checkErr(err);
          return reject(spareError);
        }
      );
    });
  }

  deactivateImportantLink(linkID: number) {
    const url = `${this.baseUrl}/important-link/admin/${linkID}/deactivate-at/?organizationID=${this.orgID}`;
    const res = this.httpClient.post(url, {});

    return lastValueFrom(res);
  }

  activateImportantLink(linkID: number) {
    const url = `${this.baseUrl}/important-link/admin/${linkID}/activate/?organizationID=${this.orgID}`;
    const res = this.httpClient.post(url, {});

    return lastValueFrom(res);
  }

  getAllImportantLinks() {
    return new Promise<Link[]>((resolve, reject) => {
      const url = `${this.baseUrl}/important-link/admin?organizationID=${this.orgID}`;
      this.httpClient.get(url).subscribe(
        (res: any) => {
          const links: Link[] = res.map((i) => {
            return {
              postID: +i.postID,
              organizationID: +i.organizationID,
              title: i.postTitle,
              description: i.postDescription,
              imageURL: i.imageURL,
              addedOn: i.addedOn,
              linkURL: i.linkURL,
              collapsed: true,
              deactivateAt: i.deactivateAt ? moment(i.deactivateAt).toDate() : null,
            };
          });
          resolve(links);
        },
        async (err) => {
          const spareError = await this.routeService.checkErr(err);
          return reject(spareError);
        }
      );
    });
  }

  getImportantLinkByID(linkID: number) {
    return new Promise((resolve, reject) => {
      const url = `${this.baseUrl}/important-link/admin/${linkID}?organizationID=${this.orgID}`;
      this.httpClient.get(url).subscribe(
        (res) => {
          resolve(res);
        },
        async (err) => {
          const spareError = await this.routeService.checkErr(err);
          return reject(spareError);
        }
      );
    });
  }

  getOrganizationName() {
    return localStorage.getItem('spareSelectedOrganizationName');
  }
}
