import { FailedTransactions } from './failed-transactions.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouteService } from '../../route.service';
import { ToastService } from '../../toast.service';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { DateRange } from '../../ui/spare-components/smart-datepicker/date-range.model';

@Injectable()
export class FailedTransactionsService {
  constructor(
    private httpClient: HttpClient,
    private routeService: RouteService,
    private toastService: ToastService
  ) {}

  failedTransactions: FailedTransactions;

  baseUrl: string = environment.baseUrl;

  orgId = localStorage.getItem('spareSelectedOrganization');
  // Get all the pins and their data
  getData(dateRange: DateRange) {
    let dateFrom = null,
      dateTo = null;
    if (dateRange.startDate && dateRange.endDate) {
      dateFrom = moment(dateRange.startDate).format('YYYY-MM-DD HH:mm:ss');
      dateTo = moment(dateRange.endDate).format('YYYY-MM-DD HH:mm:ss');
    }
    return new Promise((resolve, reject) => {
      let url = `${this.baseUrl}/failed-transactions/Admin/?organizationID=${encodeURI(
        this.orgId
      )}`;
      if (dateFrom && dateTo)
        url += `&timestampStart=${encodeURI(dateFrom)}&timestampEnd=${encodeURI(dateTo)}`;

      const failedTransactionsArray: FailedTransactions[] = [];
      this.httpClient.get<any>(url).subscribe(
        (failedTransactions: any) => {
          for (const failedTransaction of failedTransactions) {
            const tempTransaction: FailedTransactions = new FailedTransactions(
              failedTransaction.transactionID,
              failedTransaction.creditAccountID,
              failedTransaction.debitAccountID,
              this.formatDate(failedTransaction.timestamp),
              failedTransaction.reason,
              failedTransaction.parentFirstName,
              failedTransaction.parentLastName,
              failedTransaction.childName,
              failedTransaction.amount,
              failedTransaction.externalStudentID,
              failedTransaction.overridePeripheralName
            );
            failedTransactionsArray.push(tempTransaction);
          }
          resolve(failedTransactionsArray);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  formatDate(date) {
    if (date) {
      return moment(date).format('ddd, MMM DD hh:mm:ss');
    } else {
      return 'Not Completed';
    }
  }
}
