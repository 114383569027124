export class Pin {
  overrideID: number;
  phoneNumber: string;
  code: string;
  countryCode: string;

  constructor(overrideID: number, phoneNumber: string, code: string, countryCode: string) {
    this.overrideID = overrideID;
    this.phoneNumber = phoneNumber;
    this.code = code;
    this.countryCode = countryCode;
  }
}
