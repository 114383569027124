<div
  class="header-container"
  [class.left]="position === 'normal'"
  [class.right]="position === 'inverse'">
  <div class="logo-containter">
    <a (click)="toggleSidebar()" href="#" class="navigation">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <img class="logo" src="/assets/images/logo_for_statement.png" (click)="goToHome()" />
  </div>
</div>
<nb-actions
  size="medium"
  class="header-container"
  [class.right]="position === 'normal'"
  [class.left]="position === 'inverse'">
  <nb-action class="button-container">
    <button
      *ngIf="canChangeView()"
      nbButton
      status="info"
      shape="round"
      size="small"
      class="mx-3"
      (click)="onChangeView()">
      <nb-icon *ngIf="isMobile; else switchText" icon="swap-outline"></nb-icon>
      <ng-template #switchText>Switch View</ng-template>
    </button>
    <nb-user
      [nbContextMenu]="userMenu"
      nbContextMenuTag="user-profile-menu"
      [name]="user?.name"
      [picture]="user?.picture"
      [title]="user?.title"
      (showTitle)="(true)"></nb-user>
  </nb-action>
</nb-actions>
