export class FAQ {
  faqID: number;
  questionText: string;
  questionAnswer: string;
  questionTextAr: string;
  questionAnswerAr: string;

  constructor(
    faqID: number,
    questionText: string,
    questionAnswer: string,
    questionTextAr: string,
    questionAnswerAr: string
  ) {
    this.faqID = faqID;
    this.questionText = questionText;
    this.questionAnswer = questionAnswer;
    this.questionTextAr = questionTextAr;
    this.questionAnswerAr = questionAnswerAr;
  }
}
