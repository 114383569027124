import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';

type ToastType = 'basic' | 'primary' | 'success' | 'info' | 'warning' | 'danger' | 'control';

const iconClassMap: { [type in ToastType]: string } = {
  danger: 'close-outline',
  success: 'checkmark-outline',
  info: 'question-mark-outline',
  warning: 'alert-triangle-outline',
  primary: 'bell-outline',
  control: 'email-outline',
  basic: 'email-outline',
};

@Injectable()
export class ToastService {
  constructor(private toasterService: NbToastrService) {}

  show(type: ToastType, title: string, body?: string, timeout = 10000) {
    this.toasterService.show(body, title, {
      status: type,
      preventDuplicates: true,
      duplicatesBehaviour: 'previous',
      destroyByClick: true,
      duration: timeout,
      hasIcon: true,
      icon: iconClassMap[type],
    });
  }

  showSticky(type: ToastType, title: string, body: string) {
    this.show(type, title, body, 0);
  }
}
