import { Pin } from './pin.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouteService } from '../../route.service';
import { ToastService } from '../../toast.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class PinService {
  constructor(
    private httpClient: HttpClient,
    private routeService: RouteService,
    private toastService: ToastService
  ) {}

  selectedPin: Pin;

  baseUrl: string = environment.baseUrl;
  // Get all the pins and their data
  getData(): Promise<Pin[]> {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Digits/Admin/ManualOverrides/All';
      const pinsArray: Pin[] = [];
      this.httpClient.get<any>(url).subscribe(
        (pins: any) => {
          for (const pin of pins) {
            const tempPin: Pin = new Pin(
              pin.overrideID,
              pin.phoneNumber,
              pin.code,
              pin.countryCode
            );
            pinsArray.push(tempPin);
          }
          resolve(pinsArray);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  // delete pin
  deletePin(overrideID: number) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Digits/Admin/ManualOverrides/Delete';
      const response = this.httpClient.post(url, {
        overrideID: overrideID,
      });
      response.subscribe(
        () => {
          resolve('success');
          this.toastService.show('success', 'Success', 'Pin deleted successfully');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  // Add a new pin
  addPin(phoneNumber: string, countryCode: string) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Digits/Admin/ManualOverrides/Add';
      const response = this.httpClient.post(url, {
        phoneNumber,
        countryCode,
      });
      response.subscribe(
        () => {
          resolve('success');
          this.toastService.show('success', 'Success', 'Pin added successfully');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }
}
