import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { Merchant } from '../../pages/dashboard/merchant.model';
import { Organization } from '../../pages/organization/organizations/organization.model';
import { MerchantOrganizationService } from '../../pages/organization/organizations/organization.service';
import { TimeZoneService } from '../../pages/time-zone/time-zone.service';

@Component({
  selector: 'spare-change-view',
  templateUrl: './changeView.component.html',
  styleUrls: ['./changeView.component.scss'],
})
export class changeViewComponent implements OnInit {
  merchant: Merchant;
  possibleOrganizations: Organization[];
  pageLoaded = false;
  selectedItem: string;
  changedView = false;
  fromLogin = false;
  selectedMerchant = false;

  constructor(
    private authService: AuthService,
    private timezoneService: TimeZoneService,
    private organisationService: MerchantOrganizationService,
    private router: Router,
    public changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (localStorage.getItem('spareView') === 'merchant') {
      this.selectedMerchant = true;
    }
    this.authService.validateToken();
    if (this.authService.fromLogin) {
      this.fromLogin = true;
    } else {
      this.fromLogin = false;
    }
    this.authService.getMerchant().then((merchant: Merchant) => {
      this.merchant = merchant;
      this.organisationService.getOrganizations(true).then((organizations: Organization[]) => {
        this.possibleOrganizations = organizations;
        if (localStorage.getItem('spareView') === 'merchant') {
          this.selectedItem = merchant.name;
        } else {
          for (const organization of this.possibleOrganizations) {
            if (organization.organizationID == +localStorage.getItem('spareSelectedOrganization')) {
              this.selectedItem = organization.organizationName;
            }
          }
        }
        this.pageLoaded = true;
        this.changeDetector.detectChanges();
      });
    });
  }

  changeView() {
    this.changedView = true;
    this.changeDetector.detectChanges();
    if (this.merchant.name === this.selectedItem && this.selectedMerchant) {
      localStorage.setItem('spareView', 'merchant');
    } else {
      localStorage.setItem('spareView', 'organization');
      for (const organization of this.possibleOrganizations) {
        if (organization.organizationName === this.selectedItem) {
          localStorage.setItem('spareSelectedOrganization', '' + organization.organizationID);
          localStorage.setItem('spareSelectedOrganizationName', '' + organization.organizationName);
          localStorage.setItem('spareShowBilling', organization.handlesBilling ? 't' : 'f');
          localStorage.setItem(
            'spareSelectedOrganizationCurrency',
            organization.currency.currencyCode
          );
          localStorage.setItem('spareSelectedOrganizationLogo', organization.logoURL);

          this.timezoneService.setTimeZoneDBNameInCache(organization.timeZoneDatabaseName);

          const role: string[] = localStorage.getItem('spareRoles').split(',');
          for (const therole of role) {
            if (
              therole !== null &&
              therole !== '' &&
              +therole.split(':')[0] === organization.organizationID
            ) {
              localStorage.setItem('spareSelectedOrganizationRole', therole.split(':')[2]);
            }
          }
        }
      }
    }
    window.location.replace('');
  }

  onChange(selected: string, selectedMerchant: boolean) {
    if (!this.changedView) {
      this.selectedItem = selected;
      this.selectedMerchant = selectedMerchant;
      this.changeDetector.detectChanges();
      this.changeView();
    }
  }

  cancelChange() {
    this.router.navigate(['']);
  }
}
