import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouteService } from '../../route.service';
import { ToastService } from '../../toast.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class TimeZoneService {
  constructor(
    private httpClient: HttpClient,
    private routeService: RouteService,
    private toastService: ToastService
  ) {}

  baseUrl: string = environment.baseUrl;
  TIME_ZONE_DB_NAME_LOCAL_NAME = 'organizationTimeZoneDBName';

  getData() {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/time-zone';
      this.httpClient.get<any>(url).subscribe(
        (timeZones: any) => {
          resolve(timeZones);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  addTimeZone(timeZoneName, timeZoneDatabaseName, country) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/time-zone';
      const body = { timeZoneName, timeZoneDatabaseName, country };
      const response = this.httpClient.post(url, body);
      response.subscribe(
        () => {
          resolve('success');
          this.toastService.show('success', 'Success', 'Time Zone was added successfully');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  updateTimeZone(timeZoneID, timeZoneName, timeZoneDatabaseName, country) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/time-zone';
      const body = { timeZoneID, timeZoneName, timeZoneDatabaseName, country };
      const response = this.httpClient.put(url, body);
      response.subscribe(
        () => {
          resolve('success');
          this.toastService.show('success', 'Success', 'Time Zone was updated successfully');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  getCachedTimeZoneDBName(): string | null {
    return localStorage.getItem(this.TIME_ZONE_DB_NAME_LOCAL_NAME);
  }

  setTimeZoneDBNameInCache(tz: string) {
    localStorage.setItem(this.TIME_ZONE_DB_NAME_LOCAL_NAME, tz);
  }
}
